import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  topFiveCategoriesList: [],
  isDataLoading: false,
  error: '',
};

export const topFiveCategoriesSlice = createSlice({
  name: 'topFiveCategories',
  initialState,
  reducers: {
    loading: (state) => {
      return { ...state, isDataLoading: true };
    },
    fetchTopFiveCategoriesSuccess: (state, action) => {
      return {
        ...state,
        topFiveCategoriesList: action.payload,
        isDataLoading: false,
      };
    },
    fetchTopFiveCategoriesFailure: (state, action) => {
      return { ...state, isDataLoading: false, error: action.payload };
    },
  },
});

export const {
  loading,
  fetchTopFiveCategoriesSuccess,
  fetchTopFiveCategoriesFailure,
} = topFiveCategoriesSlice.actions;

export default topFiveCategoriesSlice.reducer;
