import libPhoneNumber from 'google-libphonenumber';
import { translate } from '../i18n';

const name_regex =
  /(?=^.{3,30}$)(?!(.*[0-9\u0660-\u0669].*){6})^[a-z\u0621-\u064A]([.\-_]?[a-z\u0621-\u064A0-9\u0660-\u0669]+)*$/;
const email_regex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isValidPhoneNumber = (phoneNumber) => {
  const phoneUtil = libPhoneNumber.PhoneNumberUtil.getInstance();
  const { PhoneNumberType } = libPhoneNumber;
  try {
    // parse phone number to country code and number
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber);
    return (
      phoneUtil.isValidNumber(number) &&
      (phoneUtil.getNumberType(number) === PhoneNumberType.MOBILE ||
        phoneUtil.getNumberType(number) ===
          PhoneNumberType.FIXED_LINE_OR_MOBILE ||
        phoneUtil.getNumberType(number) === PhoneNumberType.FIXED_LINE)
    );
  } catch {
    return false;
  }
};

export const sanitizeIntlPhoneNumber = (phone, country) => {
  const phoneNumber = phone.startsWith('00') ? phone.replace('00', '+') : phone;
  const phoneUtil = libPhoneNumber.PhoneNumberUtil.getInstance();
  try {
    let number = phoneUtil.parseAndKeepRawInput(phoneNumber, country);
    const callingCode = number.getCountryCode().toString();
    if (!phoneNumber.startsWith('+') && phoneNumber.startsWith(callingCode)) {
      return phoneNumber.replace(callingCode, '');
    }
    return phoneNumber;
  } catch {
    return phoneNumber;
  }
};

export const formatPhoneNumber = (phoneNumber, country) => {
  const PNF = libPhoneNumber.PhoneNumberFormat;
  const phoneUtil = libPhoneNumber.PhoneNumberUtil.getInstance();
  try {
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, country);
    return phoneUtil.format(number, PNF.E164);
  } catch {
    return phoneNumber;
  }
};

export const NationalFormat = (phoneNumber, country) => {
  const PNF = libPhoneNumber.PhoneNumberFormat;
  const phoneUtil = libPhoneNumber.PhoneNumberUtil.getInstance();
  try {
    const number = phoneUtil.parseAndKeepRawInput(phoneNumber, country);
    let nationalFormat = phoneUtil.format(number, PNF.NATIONAL);
    return nationalFormat;
  } catch {
    return phoneNumber;
  }
};

export const validationInput = (value, name, isRequired, min, max) => {
  if (isRequired && !value.length) {
    return translate('validationErrorMsg.required')(name);
  } else if (min && value.length < min) {
    return translate('validationErrorMsg.min')(name, min);
  } else if (min && value.length > max) {
    return translate('validationErrorMsg.max')(name, max);
  } else {
    //   TODO: A default has to be returned
  }
};

export const passwordStrengthBar = (password) => {
  let firstBarBackground = 'bg-green',
    secondBarBackground = 'bg-green',
    thirdBarBackground = 'bg-green';

  if (password?.length >= 8 && password.length < 12) {
    firstBarBackground = 'bg-orange';
    secondBarBackground = 'bg-orange';
    thirdBarBackground = '';
  }
  if (password?.length < 8) {
    firstBarBackground = 'bg-error';
    secondBarBackground = '';
    thirdBarBackground = '';
  }
  if (password?.length === 0) {
    firstBarBackground = 'bg-lightgray';
    secondBarBackground = 'bg-lightgray';
    thirdBarBackground = 'bg-lightgray';
  }
  return { firstBarBackground, secondBarBackground, thirdBarBackground };
};

export const validateUserName = (value) => {
  if (!name_regex.test(value)) {
    return translate('validationErrorMsg.nameError');
  }
};
export const validateEmail = (email, name) => {
  if (!email_regex.test(email))
    return translate('validationErrorMsg.invalid')(name);
};
