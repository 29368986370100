import { translate } from '../app/i18n';
import { ImageAssets } from '../assets/assetProvider';

export const mazadatValuesData = [
  {
    image: ImageAssets.valuesInnovationIllustration,
    title: `${translate('aboutUsPage.mazadatValues.firstSlide.title')}`,
    description: `${translate('aboutUsPage.mazadatValues.firstSlide.desc')}`,
  },
  {
    image: ImageAssets.valuesSecurityIllustration,
    title: `${translate('aboutUsPage.mazadatValues.secondSlide.title')}`,
    description: `${translate('aboutUsPage.mazadatValues.secondSlide.desc')}`,
  },
  {
    image: ImageAssets.valuesInclusivityIllustration,
    title: `${translate('aboutUsPage.mazadatValues.thirdSlide.title')}`,
    description: `${translate('aboutUsPage.mazadatValues.thirdSlide.desc')}`,
  },
  {
    image: ImageAssets.valuesSustainabilityIllustration,
    title: `${translate('aboutUsPage.mazadatValues.fourthSlide.title')}`,
    description: `${translate('aboutUsPage.mazadatValues.fourthSlide.desc')}`,
  },
  {
    image: ImageAssets.valuesCollaborationIllustration,
    title: `${translate('aboutUsPage.mazadatValues.fifthSlide.title')}`,
    description: `${translate('aboutUsPage.mazadatValues.fifthSlide.desc')}`,
  },
];
