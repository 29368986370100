/* eslint-disable max-lines */
const it = {
  general: {
    ofText: 'di',
    outOf: 'Su',
    comma: ',',
    currency: 'EUR',
  },
  language: {
    name: 'English',
  },
  pagination: {
    pageText: 'Pagina',
  },
  postActions: {
    done: 'Fatto!',
  },
  languagesDropdown: {
    selectLanguage: 'Seleziona lingua',
    submit: 'Invia',
  },
  readMoreComponent: {
    showLess: 'mostra meno',
    readMore: 'leggi di più...',
  },
  postCardsSlider: {
    zeroState: 'Nessun prodotto da mostrare',
    browseAll: 'Sfoglia tutto',
  },
  productConditions: {
    newText: 'Nuovo',
    mint: 'Come nuovo',
    used: 'Usato',
    defective: 'Difettoso',
    defect: 'Difetto.',
    newWithOpenBox: 'Nuovo con scatola aperta',
    newAndSealed: 'Nuovo e sigillato',
  },
  loginPage: {
    login: 'Accedi',
    loginTitle: (width, screenSize) => {
      if (width <= screenSize) {
        return 'Bentornato!';
      } else {
        return 'Compra e vendi in sicurezza e comodità';
      }
    },
    username: 'Nome utente',
    usernamePlaceholder: 'Inserisci il tuo nome utente',
    password: 'Password',
    passwordPlaceholder: 'Inserisci la tua password',
    forgetPassword: 'Password dimenticata?',
    signIn: 'Accedi',
    dontAccount: 'Non hai un account?',
    phone: 'Telefono',
    phonePlaceholder: 'Inserisci il tuo telefono',
    loginByPhone: 'Con telefono',
    loginByUsername: 'Con nome utente',
    signUp: 'Registrati',
  },
  userFeedback: {
    watchlist: {
      addedSuccessfully: 'Aggiunto alla lista dei preferiti con successo.',
      removedSuccessfully: 'Rimosso dalla lista dei preferiti con successo.',
    },
    httpErrorMsg: {
      connectionError: 'Non sei connesso a internet',
      somethingWrong: 'Qualcosa è andato storto',
    },
    categoriesPage: {
      addedSuccessfully: 'Aggiunto ai preferiti con successo.',
      removedSuccessfully: 'Rimosso dai preferiti con successo.',
    },
    loginPage: {
      loginSuccessful: 'Accesso effettuato con successo.',
      logoutSuccessfully: 'Disconnessione riuscita.',
    },
    searchForm: {
      historyCleared: 'Cronologia cancellata con successo',
    },
    otpForm: {
      successSubmission: "Ti sei registrato con successo! Scarica l'app ora!",
    },
    registrationForm: {
      recaptchaError: 'Errore nella verifica reCAPTCHA.',
    },
  },
  watchlistPage: {
    dayCount: (count) => {
      const number = count.toNumberFormat();
      return count > 1 ? `${number} Giorni` : `${number} Giorno`;
    },
    favouriteSP: 'Punto di servizio preferito',
    locationOnMaps: 'Posizione su mappe',
    workingHours: 'Orari di lavoro',
    viewOnMap: 'Visualizza su Google Maps',
    holiday: 'Vacanze',
    watchlist: 'Lista dei preferiti',
  },

  forgetPasswordForm: {
    title: 'Reimposta la tua password',
    info: 'Inserisci il numero di telefono associato al tuo account.',
    reset: 'Reimposta',
  },

  resetPasswordForm: {
    title: 'Imposta una nuova password',
    info: 'Scegli una password forte con caratteri e numeri',
    newPassword: 'Nuova password',
    newPasswordPlaceholder: 'La password deve contenere almeno 8 caratteri',
    confirmPassword: 'Conferma password',
    confirmPasswordPlaceholder: 'Reinserisci la tua nuova password',
    resetPassword: 'Reimposta password',
  },

  accountDropdown: {
    profile: 'Profilo',
    watchlist: 'Lista dei preferiti',
    notification: 'Notifiche',
    logout: 'Disconnetti',
    login: 'Accedi',
    shoppingCart: 'Carrello',
  },

  popUpsActions: {
    proceed: 'Procedi',
    cancel: 'Annulla',
  },
  legalPages: {
    top: 'SOPRA',
    topics: 'Argomenti',
    productPolicy: 'Politica sui prodotti',
    businessRegistration: 'Politica di registrazione per utenti aziendali',
    userAgreement: 'Accordo utente',
    itemCondition: 'Guida alle condizioni degli articoli',
    feesPolicy: 'Politica sulle tariffe',
    privacyPolicyTopics: {
      introduction: 'Introduzione e Consenso',
      topics: 'Argomenti coperti da questa politica sulla privacy',
      collection: 'La nostra raccolta delle tue informazioni personali',
      purposes:
        'Scopi per i quali Smarkt raccoglie, conserva, utilizza e tratta le tue informazioni personali',
      update: 'Aggiornamento della politica sulla privacy',
      personalInfo:
        'Accesso, revisione e modifica delle tue informazioni personali',
      entities: 'Entità con cui condivideremo le tue informazioni personali',
      marketing: 'Marketing e comunicazione',
      cookies: 'Cookies',
      protect: 'Protezione delle tue informazioni personali',
      general: 'Disposizioni generali',
    },
    termsAndConditionsTopics: {
      introduction: 'Introduzione e Accettazione',
      service: 'Il servizio',
      fees: 'Tariffe',
      wallet: 'Il mio portafoglio Smarkt',
      promotions: 'Servizio di promozioni a pagamento',
      conditions:
        'Condizioni di pubblicazione e utilizzo della piattaforma Smarkt',
      obligations: 'Obblighi del venditore',
      buyandbid: 'Acquisto e offerta',
      collectionpoints: 'Punti di ritiro (Click e Collect)',
      userRepresentations: "Dichiarazioni e garanzie dell'utente",
      registration: 'Registrazione e creazione di account',
      userViolations:
        'Violazioni degli utenti e sospensione o cancellazione degli account',
      eligibility:
        "Requisiti per l'adesione e la capacità di entrare nell'accordo utente",
      amendments: 'Modifiche a questo accordo utente',
      governingLaw: 'Legge applicabile',
      disputeResolutionForum: 'Forum per la risoluzione delle controversie',
      limitation: 'Limitazione di responsabilità',
      warrantiesDisclaimer: 'Esclusione di garanzia',
      customerSupport:
        'Assistenza clienti Smarkt e linee guida sui feedback degli utenti',
      indemnity: 'Indennità',
      daydeal: 'Offerta del giorno',
      licenses: 'Licenze',
      intellectualPropertyRights:
        'Diritti di proprietà intellettuale, brevetti e marchi',
      generalProvisions: 'Disposizioni generali',
    },
    productPolicyTopics: {
      introduction: 'Introduzione e Consenso',
      prohibitedProducts: 'Prodotti proibiti',
      restrictedProducts: 'Prodotti limitati',
      violation:
        'Violazione di questa politica sui prodotti proibiti e limitati',
    },
    businessRegistrationTopics: {
      registrationPolicy: 'Politica di registrazione per utenti aziendali',
      businessEntity: "Registrazione di un'entità commerciale",
    },
    conditionGuideTopics: {
      conditionGuide: 'Guida alle condizioni degli articoli',
      categoriesAndDefinitions: 'Categorie e definizioni disponibili',
    },
    feesPolicyTopics: {
      feesPolicy: 'Politica sulle tariffe',
      transactionFees: 'Tariffe di transazione',
      promotionsFees: 'Tariffe per promozioni a pagamento',
      storageFees: 'Tariffe di stoccaggio',
    },
  },
  filters: {
    sortBy: 'Ordina per',
    search: 'Cerca',
    filter: 'Filtra',
    result: 'Risultato per',
    filterSearch: 'Cerca nel filtro',
    resetAllFilter: 'Reimposta tutti i filtri',
    reset: 'Reimposta',
    apply: 'Applica',
    fromText: 'Da',
    to: 'A',
  },
  postListPage: {
    price: 'Prezzo',
    category: 'Categoria',
    pricing: 'Prezzi',
    zeroState: 'Non ci sono risultati da mostrare',
    noFilterResult: 'Non ci sono risultati con le tue scelte di filtro',
    clearAll: 'Cancella tutto',
    sellingMethod: 'Metodo di vendita',
    sliderSectionTitle: 'Post in evidenza',
    filterResult: (count) => {
      if (count === 1) return `1 Post trovato`;
      if (count > 100) return `100+ Post trovati`;
      return `${count.toNumberFormat()} Post trovati`;
    },
    filterChipsSeeMore: 'Vedi altro',
    filterChipsSeeLess: 'Vedi meno',
    sorting: {
      lowToHigh: `Prezzo: dal più basso al più alto`,
      highToLow: `Prezzo: dal più alto al più basso`,
      newlyAdded: `Appena aggiunto`,
      sellerRating: `Valutazione del venditore`,
      endingSoonest: `Scade a breve`,
    },
    keys: {
      category: 'Categoria',
      price: 'Prezzo',
      sellingMethod: 'Metodo di vendita',
      condition: 'Condizione',
      secured: 'Protetto da Smarkt',
    },
  },
  searchPage: {
    zeroState: `Non ci sono risultati con la tua ricerca`,
    resultFor: (keyword) => `Risultati di ricerca per "${keyword}"`,
  },
  searchResult: (count) => {
    if (count === 1) return `1 Risultato`;
    if (count > 100) return `100+ Risultati`;
    return `${count.toNumberFormat()} Risultati`;
  },
  httpErrorMsg: {
    somethingWrong: 'Qualcosa è andato storto',
    notFound: 'Non trovato',
    connectionError: 'Non sei connesso a internet',
    unauthorized: 'Non autorizzato',
    noRefreshToken: 'Nessun token di aggiornamento',
    pleaseTryAgain: 'Errore interno del server, riprova più tardi',
  },
  notFoundPage: {
    title: 'Ooops, questa pagina non è stata trovata',
    description:
      'Siamo davvero spiacenti, ma la pagina che hai richiesto è mancante... Forse possiamo aiutarti o tornare alla pagina iniziale',
    backToHome: 'Torna alla Home',
    contactUs: 'Contattaci',
  },
  reportAbuseComponent: {
    reportAbuse: 'Segnala abuso',
  },
  createPostButton: {
    createAuctionTitle: 'Crea asta',
    createDirectSaleTitle: 'Crea vendita diretta',
    createAuctionSubtitle: 'Prezzo di mercato più alto',
    createDirectSaleSubtitle: 'Il tuo prezzo di acquisto immediato',
    mazadatValetText: 'Smarkt Valet',
    mazadatValetLabel: 'Nuovo',
  },
  smallPostTimerComponent: {
    ends: 'Termina',
    inText: 'in',
    expired: 'Scaduto',
    timer3MinsRuleText: 'Ultima occasione',
  },
  postDetailsPage: {
    productDescription: 'Descrizione del prodotto',
    questionsAndAnswers: 'Domande & Risposte',
    loadMore: 'Carica altro',
    aboutSeller: 'Informazioni sul venditore',
    securedBy: 'Protetto da',
    dontMissOut: 'Non perderlo!',
    willExpireIn: 'Scadrà in',
    days: 'Giorni',
    hours: 'Ore',
    minutes: 'Minuti',
    seconds: 'Secondi',
    material: 'Materiale',
    buyNow: 'Acquista ora',
    bidNow: "Fai un'offerta ora",
    buyFor: 'Acquista per',
    youMadePurchase: 'Hai acquistato questo articolo il',
    itemsAreRemaining: 'articoli rimanenti',
    yourBidIsLeading: 'La tua offerta è in testa ora!',
    noDescription: "Non c'è ancora una descrizione!",
    noQuestionsAndAnswers: 'Non ci sono ancora domande e risposte',
    zeroState: 'Non ci sono ancora recensioni!',
    loadMoreQuestions: 'Carica più domande',
    itemsSoon: 'Articoli a breve',
    soldOut: 'esaurito!',
    itemSoldOut: 'Questo post è esaurito',
    thisPostNotAvailable: 'Questo post non è disponibile',
    notSecured: 'Venditore-Acquirente Diretto',
    securedItemMobileView: 'Questo articolo è protetto da Smarkt',
    zoomImage: "Passa sull'immagine per ingrandirla",
    pickupIs: 'Ritiro',
    deliverBy: 'Consegnato da',
    pickupNotAvailable: 'non disponibile',
    pickupAvailable: 'disponibile',
    mazadat: 'Smarkt',
    reply: 'Rispondi',
    maxQtyExceededText: 'Quantità massima superata!',
    differentCountryState: {
      title: 'Ooops, questo post non è disponibile nel tuo paese.',
      description:
        'Siamo davvero spiacenti, ma il post che hai appena aperto non è disponibile nel tuo paese... Forse possiamo aiutarti o tornare alla home page',
    },
    brandInfoComponent: {
      category: 'Categoria',
      subCategory: 'Sottocategoria',
      warranty: 'Garanzia',
      endTime: 'Ora di fine',
    },
    actions: {
      postSimilarButton: 'Pubblica simile',
      shareButton: 'Condividi',
    },
    bid: (number) => {
      if (number === 1) {
        return `1 Offerta`;
      } else {
        return `${number.toNumberFormat()} Offerte`;
      }
    },
    viewAnswers: 'Visualizza risposte',
    reviewZeroState: 'Non ci sono ancora recensioni!',
    viewReviews: 'Visualizza recensioni',
    tabToExpand: 'Tocca per espandere',
    addReview: 'Aggiungi recensione',
    comments: 'Commenti',
    totalComments: 'Numero totale di commenti',
    viewReplies: (number) => {
      if (number === 1)
        return `Visualizza ${number.toNumberFormat()} risposta precedente...`;
      else
        return `Visualizza ${number.toNumberFormat()} risposte precedenti...`;
    },
    hideReplies: 'Nascondi risposte',
    whatAboutThose: 'Che ne dici di questi?',
    cantFetchSection: `Impossibile visualizzare i dati della sezione`,
    backToHome: 'Torna alla Home',
    reportAProblem: 'Segnala un problema',
    cancel: 'Annulla',
    viewAllItems: 'Visualizza tutti gli articoli',
  },
  widgets: {
    securedItem: 'Questo articolo è protetto da Smarkt',
    securedItemTooltip:
      'Accedi ai punti di servizio Smarkt per completare i tuoi ordini in sicurezza',
    notSecured: 'Venditore-Acquirente Diretto',
    secure: '100% Sicuro',
    payments: 'Metodi di pagamento diversificati',
    delivery: 'Consegna del prodotto',
    auctionOrDirectSale: 'Asta o vendita diretta',
    servicePointsNetwork: 'Rete di punti di servizio espandibile',
    winWinComissions: 'Commissioni win-win',
    digitalInvoice: 'Fattura digitale',
    activeItems: 'Post attivi da',
    viewAllItems: 'Visualizza tutti gli articoli',
    secureDes:
      'Con Smarkt, non corri alcun rischio di essere rapinato, molestato o minacciato in alcun modo.',
    deliveryDes:
      'Con Smarkt, puoi far consegnare il tuo ordine a casa tua, ovunque e in qualsiasi momento.',
    paymentsDes:
      'Puoi scegliere di pagare facilmente tramite il portafoglio digitale di Smarkt, carte di credito, a rate o in contanti.',
    auctionOrDirectSaleDes:
      "Su Smarkt puoi scegliere di vendere i tuoi articoli tramite un'asta, una vendita diretta a prezzo fisso o tramite un formato ibrido tra i due.",
    servicePointsNetworkDes:
      "Nei punti di servizio Smarkt, i venditori e gli acquirenti possono ispezionare i prodotti prima di completare l'acquisto senza doversi preoccupare di incontrare o fidarsi di estranei.",
    winWinComissionsDes:
      'Paghi la nostra commissione solo quando vendi i tuoi prodotti. Crea quanti più post gratuiti desideri, e quando vendi vieni pagato.',
    digitalInvoiceDes:
      'Con Smarkt, puoi generare le tue fatture digitali per i tuoi articoli venduti, in modo sicuro e conveniente.',
    learnMore: 'Scopri di più',
  },
  biddingHistorySidebar: {
    viewHistory: 'Visualizza cronologia',
    biddingHistory: 'Cronologia delle offerte',
    biddingPrice: "Prezzo dell'offerta:",
    bids: 'Offerte',
    bidders: 'Offerenti',
    highestBid: 'Offerta più alta',
    noBidsAvailable: 'Nessuna offerta disponibile',
    beTheFirstToBid: 'Ancora nessuna offerta! Sii il primo.',
    lastBid: 'Ultima offerta',
    nextBid: 'Prossima offerta',
    bidNow: "Fai un'offerta ora",
  },
  header: {
    shopByCategory: 'Acquista per categoria',
    createPost: 'Crea post',
    becomeBusiness: "Diventa un'azienda?",
  },
  footer: {
    safePayments: 'Puoi pagare tramite',
    allRights: 'Tutti i diritti riservati.',
    mazadatInc: '©Smarkt SAE.',
    contactInfo: 'Informazioni di contatto',
    customerService: 'Servizio clienti',
    termsConditions: 'Termini & Condizioni',
    contactUs: 'Contattaci',
    privacyPolicy: 'Politica sulla privacy',
    downloadApp: 'Scarica la nostra app',
    mazadat: 'Smarkt',
    aboutUs: 'Chi siamo',
    howITWorks: 'Come funziona',
    becomeBusiness: "Diventa un'azienda?",
  },
  footerMobile: {
    downloadApp: 'Scarica la nostra app',
  },
  sharePopup: {
    shareThisPost: 'Condividi questo post',
    copyLink: 'O copia il link',
    copiedToClipboard: 'Copiato negli appunti',
    copy: 'Copia',
  },
  aboutSellerView: {
    verifiedSeller: 'Venditore verificato',
    viewProfile: 'Visualizza il profilo del venditore',
    totalRatingAsSeller: 'Valutazioni totali come venditore',
    showReviews: 'Visualizza recensioni',
    hideReviews: 'Nascondi recensioni',
    reviewsIn: 'Recensioni negli ultimi ',
    soldItems: (number) => {
      if (number < 1) return `Membro da`;
      else if (number > 1) return `articoli da`;
      else return 'articolo da';
    },
    sold: 'Venduto',
    outOf: 'su',
    chatWithSeller: 'Chatta con il venditore',
    loadMoreReview: 'Carica più recensioni',
    addReview: 'Aggiungi recensione',
    zeroState: 'Nessuna recensione ancora!',
  },
  ratingView: {
    commitment: 'Impegno',
    communication: 'Comunicazione',
    speedAndDelivery: 'Velocità e consegna',
    overallExperience: 'Esperienza complessiva',
    totalRatingText: 'Valutazioni totali',
    viewDetails: 'Visualizza dettagli',
  },
  pagerButtonComponent: {
    next: 'Prossimo',
    previous: 'Precedente',
  },
  postCard: {
    qtyText: (quantity, bids, discount) => {
      const number = quantity.toNumberFormat();
      if (discount === 'amount') return '';
      if (bids > 0 || !!discount) return `${number} Rimasti`;
      if (quantity === 1) return `Solo 1 articolo disponibile`;
      if (quantity > 1) return `${number} articoli disponibili`;
      if (quantity === 0) return 'Nessun articolo disponibile';
      return '';
    },
    bidsNumberText: (bids, discount) => {
      if (discount === 'amount') return '';
      if (bids === 1) return `1 Offerta`;
      if (bids > 1) return `${bids.toNumberFormat()} Offerte`;
      return ``;
    },
    discountValuePrefix: 'Risparmia',
    off: 'DI SCONTO',
    bidNow: "Fai un'offerta ora",
    buyNow: 'Acquista ora',
    soldOut: 'Esaurito!',
    notAvailableViewComponent: {
      notAvailable: 'Non disponibile',
    },
  },
  homePage: {
    title: 'Resta sintonizzato! La home page sarà presto lanciata!',
    description: 'Puoi cercare articoli o usare il Menu per sfogliare Smarkt',
    featuredPostsTitle: 'Post in evidenza',
    topCategoriesTitle: 'Categorie principali',
    startingFromValue: '100 EUR',
    dayDeal: {
      title: 'Offerta del giorno',
      dontMissOutWillExpiresIn: 'Non perderlo! Il post scade in',
      discoverMore: 'Scopri di più',
      qtyText: (value) => {
        if (value === 1) return `Solo 1 articolo rimasto`;
        if (value > 1) return `${value.toNumberFormat()} articoli rimasti`;
        if (value === 0) return 'Nessun articolo rimasto';
        return '';
      },
    },
    comingSoon: {
      title: 'Resta sintonizzato per grandi offerte di risparmio!',
      weWillNotifyIn: 'Ti avviseremo in',
      downloadAppGetLatest: "Scarica l'app e ottieni le ultime offerte!",
    },
  },
  cookies: {
    siteCookies: 'Hey! Questo sito utilizza i cookie.',
    cookiesDescription:
      'Usiamo i cookie per migliorare la tua esperienza su questo sito.',
    acceptCookies: `Va bene Cookie!`,
  },
  megaMenu: {
    close: 'Chiudi',
  },
  downloadPopup: {
    content: 'Scarica la nostra app per utilizzare questa funzione.',
    close: 'Chiudi',
    downloadAppNow: 'Scarica Smarkt ora!',
  },
  sendMessageForm: {
    title: 'Contattaci!',
    description: `Crediamo che buone conversazioni generino grandi idee!`,
    fullName: 'Nome completo',
    nameErr: 'Nome',
    subjErr: 'Oggetto',
    mobileErr: 'numero di cellulare',
    emailAddress: 'Inserisci il tuo indirizzo e-mail',
    emailAddressErr: 'indirizzo e-mail',
    mobileNumber: 'Inserisci il tuo numero di cellulare',
    subject: 'Inserisci il tuo oggetto',
    message: 'Si prega di digitare il messaggio',
    submitMessage: 'Invia messaggio',
    closeNow: 'Chiudi',
    thankYou: 'Grazie!',
    characters: 'Caratteri',
    successMessageDescription:
      'Il tuo messaggio è stato inviato con successo. Il nostro team ti risponderà il prima possibile.',
  },
  contactUsPage: {
    weAreHere: {
      title: `Contattaci!`,
      description: `Condividi con noi i tuoi pensieri!`,
      headOffice: 'Sede centrale',
      nasrCity: 'Nasr City',
      nasrCityAddress: '17 Mostafa EL-Nahaas Nasr City, Il Cairo',
      phoneNumber: 'Numero di telefono',
      mobileNumber: 'Numero di cellulare',
    },
    letUsTalk: {
      title: `Hai bisogno di ulteriore aiuto?`,
      shareExcitement: 'Condividi con noi la tua esperienza!',
      sendInquiry: 'Oppure inviaci la tua richiesta',
    },
    ourServicePoints: {
      title: 'I nostri punti di servizio',
      description: 'Siamo sempre qui per te!',
      viewMap: 'Visualizza mappa',
      locationOnMaps: 'Posizione su mappe',
      workingHours: 'Orari di lavoro',
      viewOnMap: 'Visualizza su Google Maps',
      holiday: 'Vacanze',
    },
    contactFromHeart: {
      title: 'Rimani in contatto!',
      description: `Seguici!`,
    },
  },
  getHelpComponent: {
    title: `Hai bisogno di ulteriore aiuto?`,
    shareExcitement: 'Condividi con noi la tua esperienza!',
    sendInquiry: 'Oppure inviaci la tua richiesta',
  },
  textFieldComponent: {
    characters: 'Caratteri',
    optionalLabel: 'Opzionale',
    password: 'Password',
  },
  phoneNumberComponent: {
    phoneNumber: 'Numero di cellulare',
    code: 'Codice',
    selectCode: 'Seleziona codice',
  },
  registrationForm: {
    joinUs: 'Unisciti a noi',
    byContinuing: (width, screenSize) => {
      if (width <= screenSize) {
        return 'Procedendo, accetti i termini di Smarkt';
      } else {
        return 'Continuando, accetti i nostri';
      }
    },
    termsOfService: (width, screenSize) => {
      if (width <= screenSize) {
        return 'Termini di servizio';
      } else {
        return 'Termini e Condizioni';
      }
    },
    registeredBusiness: 'Azienda registrata',
    invitationCode: 'Codice di invito',
    verify: 'Verifica',
    phoneNumber: 'Numero di cellulare',
    code: 'Codice',
    username: 'Nome utente',
    enterUsername: 'Inserisci il tuo nome utente',
    password: 'Password',
    passwordCharacters: 'Inserisci la tua password',
    title: (width, screenSize) => {
      if (width <= screenSize) {
        return 'Cominciamo';
      } else {
        return "Se l'opportunità non bussa, costruisci una porta.";
      }
    },
    successSubmission: "Ti sei registrato con successo! Scarica l'app ora!",
    businessTooltip:
      "Registrati come utente aziendale e approfitta dell'esperienza di e-commerce unica di Smarkt per espandere la tua attività.",
    invitationCodeTooltip:
      'Inserisci qui il tuo codice di invito se sei stato invitato da un utente.',
    userNameCheckList: {
      usernameCharLength: 'Da 3 a 30 caratteri (lettere o numeri).',
      noSpecialChar: "Nessun carattere speciale all'inizio o alla fine.",
      noSpaces: 'Nessuno spazio.',
      noUpperCase: 'Nessuna lettera maiuscola (CAPS).',
      lessThanSix: 'Massimo 5 numeri.',
    },
    invitationCodePlaceHolder: 'Inserisci il codice di invito',
    signUp: 'Registrati',
    signIn: 'Accedi',
    charAnd: '&',
    codeError: 'Si prega di selezionare un codice',
    selectCode: 'Seleziona codice',
    alreadyAccount: 'Hai già un account?',
    privacyPolicy: 'Politica sulla privacy',
  },
  validationErrorMsg: {
    required: (name) => `${name} è richiesto`,
    min: (name, min) => `${name} deve essere di almeno ${min} caratteri`,
    max: (name, max) => `${name} deve essere di massimo ${max} caratteri`,
    invalid: (name) => `${name} non valido!`,
    nameError: 'Nome utente non valido, controlla le regole',
    confirmPassword: 'Le password non corrispondono',
  },
  otpForm: {
    title: 'Verifica il tuo numero di cellulare.',
    enterTheCode: 'Inserisci il codice numerico',
    changeNumber: 'Cambia numero',
    notReceiveCode: 'Non hai ricevuto il codice?',
    resendIn: 'Rinvia in',
    resendButton: 'Rinvia',
    verify: 'Verifica',
    enterTheCodeSentToEmail: 'Inserisci il codice inviato al tuo email',
    changeEmail: 'Cambia email',
    otpInfo: 'Inserisci il codice a 4 cifre inviato al tuo cellulare:',
    whatsapp: 'O tramite WhatsApp',
    successSubmission:
      "Ti sei registrato con successo! Scarica l'app ora ed esplora le funzionalità di Smarkt.",
    whatsappCodeSentSucess: 'Codice inviato con successo a WhatsApp',
  },
  passwordStrength: {
    weakPassword: 'Password debole!',
    averagePassword: 'Password media!',
    greatPassword: 'Ottima password!',
  },
  breadCrumb: {
    home: 'Home',
    editPost: 'Modifica post',
    similarPost: 'Post simile',
    sharePost: 'Condividi post',
    addToWatchList: 'Aggiungi alla lista dei preferiti',
    reportAbuse: 'Segnala un problema',
    privacyPolicy: 'Politica sulla privacy',
    termsAndConditions: 'Termini & Condizioni',
    productPolicy: 'Politica sui prodotti',
    businessRegistrationPolicy:
      'Politica di registrazione per utenti aziendali',
    feesPolicy: 'Politica sulle tariffe',
    aboutUs: 'Chi siamo',
    howItWorks: 'Come funziona',
    categories: 'Categorie',
    sellerProfile: 'Profilo del venditore',
    comingSoon: 'In arrivo',
    notFound: 'Non trovato',
    contactUs: 'Contattaci',
  },
  bottomMenu: {
    profile: 'Profilo',
    search: 'Cerca',
    categories: 'Categorie',
    home: 'Home',
  },
  searchView: {
    searchFieldPlaceHolder: `Sto cercando...`,
  },
  aboutUsPage: {
    shoppingExperience: {
      title:
        "Su Smarkt creiamo percorsi per acquirenti e venditori verso un'esperienza di shopping unica",
      description: `Siamo la prima piattaforma di mercato X2X e di aste in Medio Oriente e Africa, offrendo un'esperienza di shopping unica ai suoi utenti; dove privati (C2C) e aziende di diverse dimensioni (B2C) possono vendere i loro articoli e prodotti. Gli utenti possono vendere articoli in aste a tempo, elenchi a prezzo fisso "Compra ora", o un formato ibrido, mentre gli acquirenti possono godere della varietà di offerte combinate in un unico luogo.`,
      scroll: 'scorri',
    },
    innerSections: {
      onlineAndOffline: 'Collegare i mondi online e offline',
      onlineAndOfflineDesc: `Collegando i mondi online e offline, Smarkt ha innovato una rete di punti di servizio fisici decentralizzata senza precedenti, che è l'epitome di un modello "senza fiducia", dove tutte le parti; individui, imprenditori, acquirenti e venditori non devono affidarsi o fidarsi di estranei/la seconda parte. Questo modello unico supporta commercianti e fornitori, comprese le piccole e micro-imprese, con diversi vantaggi, come spazi di stoccaggio, logistica e requisiti di servizio rapido, offrendo loro infinite opportunità per migliorare il loro business e promuovere ulteriormente i loro marchi.`,
      innovation: `Costruire canali X2C innovativi e sostenibili`,
      innovationDesc: `Su Smarkt, aspiriamo a costruire una comunità per tutti attraverso esperienze di e-commerce scalabili, dove tutti possono crescere e prosperare. Crediamo fermamente che i piccoli sforzi possano fare una grande differenza, ed è per questo che siamo radicati fin dall'inizio di Smarkt per promuovere l'idea del re-commerce (acquisto e vendita di prodotti usati), incoraggiando acquirenti e venditori a estendere la vita dei prodotti per contribuire a un pianeta più sano e a un'economia verde.`,
      safety: `Offrire comodità e sicurezza agli utenti`,
      safetyDesc: `Ci impegniamo a migliorare la vita delle persone attraverso l'accessibilità dei servizi di prodotto di Smarkt, associata alla comodità e alla sicurezza offerta dal concetto dei suoi punti di servizio unici in tutto il paese, offrendo una situazione win-win per aziende di tutte le dimensioni; in quanto possono beneficiare di una varietà di servizi di supporto logistico, stoccaggio e gestione degli ordini, e gli acquirenti che possono ispezionare i loro acquisti in modo sicuro e anonimo.`,
      safetyDescSub: `Offrendo una varietà di soluzioni di pagamento digitalizzate, Smarkt promette ai suoi clienti acquisti accessibili e convenienti attraverso la sua piattaforma e opzioni di pagamento rateizzate.`,
    },
    mazadatValues: {
      sectionTitle: `Valori di Smarkt`,
      firstSlide: {
        title: 'Innovazione',
        desc: "Il nostro modello di business inventato offre ai nostri utenti un'esperienza di vendita al dettaglio unica. Il nostro sistema di applicazione utilizza la tecnologia più recente per creare un'esperienza di acquisto senza problemi, a portata di clic.",
      },
      secondSlide: {
        title: 'Sicurezza',
        desc: "La nostra piattaforma di mercato è tutta incentrata sulla sicurezza e sulla costruzione di fiducia in un mondo senza fiducia. Forniamo un servizio di deposito a garanzia, che garantisce la soddisfazione sia per l'acquirente che per il venditore.",
      },
      thirdSlide: {
        title: 'Inclusività',
        desc: "Ogni utente può accedere alla nostra piattaforma, indipendentemente dalla sua lingua, posizione, stato finanziario e esigenze di stile di vita. L'inclusività e l'accessibilità di Smarkt si riflettono al meglio nella sua struttura multilingue, nelle molteplici opzioni di pagamento e nelle opzioni di spedizione flessibili per soddisfare diverse esigenze.",
      },
      fourthSlide: {
        title: 'Sostenibilità',
        desc: "Promuoviamo l'idea del re-commerce; incoraggiando acquirenti e venditori a prolungare la vita degli articoli usati. Questo contribuisce alla riduzione dei rifiuti e alla conservazione delle risorse.",
      },
      fifthSlide: {
        title: 'Collaborazione',
        desc: "Aspiriamo a costruire una comunità per tutti attraverso un'esperienza di e-commerce scalabile. Che tu sia uno studente, una mamma o un fornitore che cerca di espandere la tua portata, ti invitiamo a unirti alla nostra comunità e a collaborare con noi.",
      },
    },
    ourServicesPoint: {
      title: `I nostri punti di servizio`,
      servicePoints: {
        heliopolis: 'Heliopolis',
        el_Mohandseen: `El Mohandseen`,
        nasrCity: 'Nasr City',
        maadi: 'Maadi',
      },
    },
    subscription: {
      title: `Ci piacerebbe sapere di più sulla tua attività`,
    },
  },
  howItWorksPage: {
    header: {
      smartAndSafe: 'intelligente. sicuro.',
      convenient: 'comodo',
      bidBuyAndSell: 'offri, compra & vendi',
      description:
        'Con un solo account su Smarkt, puoi fare offerte, comprare e vendere',
    },
    fiveProducts: {
      title: '5 diverse condizioni di prodotto',
      newText: 'Nuovo',
      mint: 'Come nuovo',
      used: 'Usato',
      defective: 'Difettoso',
      openBox: 'Scatola aperta',
    },
    checkOutMazadat: {
      title: `Scopri le inserzioni di Smarkt in oltre 35 categorie`,
    },
    forgetAboutScam: {
      title: 'Dimentica le truffe! Protetto da Smarkt',
      description:
        'Una rete di punti di servizio espandibile dove puoi acquistare e vendere in modo sicuro, comodo e riservato!',
    },
    payment: {
      title: `Opzioni di pagamento sicure e diversificate`,
    },
    mazadatInNumbers: {
      title: `Smarkt in numeri`,
      circles: {
        download: 'Secondi per il download',
        auctions: "Secondi per avviare un'asta o un post di vendita diretta",
        dropOff: 'Ore consentite per consegnare o ritirare il tuo articolo',
        returnRequest:
          'Finestra di 48 ore per presentare una richiesta di reso',
        sellerBuyer: 'Protezione acquirente-venditore',
        conditions: 'Diverse condizioni del prodotto',
        increasePosts:
          'Piani promozionali per aumentare le vendite del tuo post',
      },
    },
    whySecured: {
      cards: {
        pickup: 'Consegna & Ritiro presso il punto di servizio',
        inspect: 'Ispeziona prima di ritirare un articolo',
        deliveryService: 'Servizi di consegna e stoccaggio disponibili.',
        returnRequest:
          'Finestra di 48 ore per presentare una richiesta di reso',
      },
      title: 'Perché "Protetto da Smarkt"',
    },
    shopSmart: {
      title: 'Fai acquisti intelligenti ogni giorno',
    },
  },
  categoriesPage: {
    favouriteCategories: 'Categorie preferite',
    allCategories: 'Tutte le categorie',
    searchPlaceholder: 'Cerca nelle categorie',
    noFavouriteSelected:
      "Premi sull'icona a forma di cuore per aggiungere ai tuoi preferiti",
  },
  sellerProfilePage: {
    showContact: 'Mostra contatto',
    shareProfile: 'Condividi profilo',
    reviews: 'Recensioni',
    activePost: 'Post attivi',
    sellerReviews: 'Recensioni del venditore',
    outOf: 'su',
    reportAbuse: 'Segnala un problema',
    viewReviews: 'Visualizza recensioni',
    zeroState: 'Nessuna recensione',
    noActivePosts: 'Nessun post attivo da mostrare',
    newToOld: 'Dal più recente al più vecchio',
    oldToNew: 'Dal più vecchio al più recente',
    lowToHigh: 'Valutazione più bassa al più alta',
    highToLow: 'Valutazione più alta alla più bassa',
  },
  underConstructionPage: {
    title: `Oh! Ci dispiace, questa pagina è in costruzione.`,
    moreText:
      'Nel frattempo, puoi scaricare la nostra app per godere di tutte le funzionalità di Smarkt',
  },
  downloadAppFooter: {
    title: 'Unisciti ora ed esplora il mondo di Smarkt',
    description: 'Scarica la nostra app da qui',
  },
  serviceWorker: {
    installedMessage: "L'app è stata installata",
    updateMessage: "C'è un nuovo aggiornamento, per favore ricarica",
  },
  countrySelect: {
    title: 'Selezione del paese',
    apply: 'Applica',
  },
};

export default it;
