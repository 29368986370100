/* eslint-disable max-lines */
const fr = {
  general: {
    ofText: 'de',
    outOf: 'Sur',
    comma: ',',
    currency: 'EUR',
  },
  language: {
    name: 'English',
  },
  pagination: {
    pageText: 'Page',
  },
  postActions: {
    done: 'Terminé!',
  },
  languagesDropdown: {
    selectLanguage: 'Sélectionner la langue',
    submit: 'Soumettre',
  },
  readMoreComponent: {
    showLess: 'montrer moins',
    readMore: 'lire la suite...',
  },
  postCardsSlider: {
    zeroState: 'Aucun produit à afficher',
    browseAll: 'Parcourir tout',
  },
  productConditions: {
    newText: 'Neuf',
    mint: 'Comme neuf',
    used: 'Utilisé',
    defective: 'Défectueux',
    defect: 'Défaut',
    newWithOpenBox: 'Neuf avec boîte ouverte',
    newAndSealed: 'Neuf et scellé',
  },
  loginPage: {
    login: 'Se connecter',
    loginTitle: (width, screenSize) => {
      if (width <= screenSize) {
        return 'Content de te revoir!';
      } else {
        return 'Achetez & vendez en toute sécurité et commodité';
      }
    },
    username: "Nom d'utilisateur",
    usernamePlaceholder: "Entrez votre nom d'utilisateur",
    password: 'Mot de passe',
    passwordPlaceholder: 'Entrez votre mot de passe',
    forgetPassword: 'Mot de passe oublié?',
    signIn: 'Se connecter',
    dontAccount: "Vous n'avez pas de compte?",
    phone: 'Téléphone',
    phonePlaceholder: 'Entrez votre numéro de téléphone',
    loginByPhone: 'Par téléphone',
    loginByUsername: "Par nom d'utilisateur",
    signUp: "S'inscrire",
  },
  userFeedback: {
    watchlist: {
      addedSuccessfully: 'Ajouté à la liste de suivi avec succès.',
      removedSuccessfully: 'Supprimé de la liste de suivi avec succès.',
    },
    httpErrorMsg: {
      connectionError: "Vous n'êtes pas connecté à Internet",
      somethingWrong: "Quelque chose s'est mal passé",
    },
    categoriesPage: {
      addedSuccessfully: 'Ajouté aux favoris avec succès.',
      removedSuccessfully: 'Supprimé des favoris avec succès.',
    },
    loginPage: {
      loginSuccessful: 'Vous vous êtes connecté avec succès.',
      logoutSuccessfully: 'Déconnexion réussie.',
    },
    searchForm: {
      historyCleared: 'Historique effacé avec succès',
    },
    otpForm: {
      successSubmission:
        "Vous vous êtes inscrit avec succès ! Téléchargez l'application maintenant !",
    },
    registrationForm: {
      recaptchaError: 'Erreur de vérification reCAPTCHA.',
    },
  },
  watchlistPage: {
    dayCount: (count) => {
      const number = count.toNumberFormat();
      return count > 1 ? `${number} Jours` : `${number} Jour`;
    },
    favouriteSP: 'Point de service préféré',
    locationOnMaps: 'Emplacement sur les cartes',
    workingHours: "Horaires d'ouverture",
    viewOnMap: 'Voir sur Google Maps',
    holiday: 'Vacances',
    watchlist: 'Liste de suivi',
  },

  forgetPasswordForm: {
    title: 'Réinitialiser votre mot de passe',
    info: 'Entrez le numéro de téléphone associé à votre compte.',
    reset: 'Réinitialiser',
  },

  resetPasswordForm: {
    title: 'Définir un nouveau mot de passe',
    info: 'Choisissez un mot de passe fort avec des caractères et des chiffres',
    newPassword: 'Nouveau mot de passe',
    newPasswordPlaceholder:
      'Le mot de passe doit contenir au moins 8 caractères',
    confirmPassword: 'Confirmez le mot de passe',
    confirmPasswordPlaceholder: 'Entrez à nouveau votre nouveau mot de passe',
    resetPassword: 'Réinitialiser le mot de passe',
  },

  accountDropdown: {
    profile: 'Profil',
    watchlist: 'Liste de suivi',
    notification: 'Notifications',
    logout: 'Déconnexion',
    login: 'Connexion',
    shoppingCart: 'Panier',
  },

  popUpsActions: {
    proceed: 'Continuer',
    cancel: 'Annuler',
  },
  legalPages: {
    top: 'HAUT',
    topics: 'Sujets',
    productPolicy: 'Politique de produit',
    businessRegistration:
      "Politique d'inscription des utilisateurs professionnels",
    userAgreement: 'Contrat utilisateur',
    itemCondition: "Guide de l'état des articles",
    feesPolicy: 'Politique de frais',
    privacyPolicyTopics: {
      introduction: 'Introduction et Consentement',
      topics: 'Sujets couverts par cette politique de confidentialité',
      collection: 'Notre collecte de vos informations personnelles',
      purposes:
        'Objectifs pour lesquels Smarkt collecte, stocke, utilise et traite vos informations personnelles',
      update: 'Mise à jour de la politique de confidentialité',
      personalInfo:
        'Accès, révision et modification de vos informations personnelles',
      entities:
        'Entités avec lesquelles nous partagerons vos informations personnelles',
      marketing: 'Marketing et communication',
      cookies: 'Cookies',
      protect: 'Protection de vos informations personnelles',
      general: 'Dispositions générales',
    },
    termsAndConditionsTopics: {
      introduction: 'Introduction et Acceptation',
      service: 'Le Service',
      fees: 'Frais',
      wallet: 'Mon portefeuille Smarkt',
      promotions: 'Service de promotions payantes',
      conditions:
        "Conditions de publication et d'utilisation de la plateforme Smarkt",
      obligations: 'Obligations du vendeur',
      buyandbid: 'Achat et enchère',
      collectionpoints: 'Points de collecte (Click & Collect)',
      userRepresentations: "Représentations et garanties de l'utilisateur",
      registration: 'Enregistrement et création de compte',
      userViolations:
        "Violations de l'utilisateur et suspension ou annulation de comptes",
      eligibility:
        "Admissibilité à l'inscription et capacité à conclure le contrat utilisateur",
      amendments: 'Modifications de ce contrat utilisateur',
      governingLaw: 'Loi applicable',
      disputeResolutionForum: 'Forum de résolution des litiges',
      limitation: 'Limitation de responsabilité',
      warrantiesDisclaimer: 'Clause de non-responsabilité',
      customerSupport:
        'Support client de Smarkt et lignes directrices sur les retours des utilisateurs',
      indemnity: 'Indemnité',
      daydeal: 'Offre du jour',
      licenses: 'Licences',
      intellectualPropertyRights:
        'Droits de propriété intellectuelle, brevets et marques',
      generalProvisions: 'Dispositions générales',
    },
    productPolicyTopics: {
      introduction: 'Introduction et Consentement',
      prohibitedProducts: 'Produits interdits',
      restrictedProducts: 'Produits restreints',
      violation:
        'Violation de cette politique sur les produits interdits et restreints',
    },
    businessRegistrationTopics: {
      registrationPolicy:
        "Politique d'inscription des utilisateurs professionnels",
      businessEntity: "Enregistrement d'une entité commerciale",
    },
    conditionGuideTopics: {
      conditionGuide: "Guide de l'état des articles",
      categoriesAndDefinitions: 'Catégories et définitions disponibles',
    },
    feesPolicyTopics: {
      feesPolicy: 'Politique de frais',
      transactionFees: 'Frais de transaction',
      promotionsFees: 'Frais de promotions payées',
      storageFees: 'Frais de stockage',
    },
  },
  filters: {
    sortBy: 'Trier par',
    search: 'Recherche',
    filter: 'Filtrer',
    result: 'Résultat pour',
    filterSearch: 'Rechercher dans le filtre',
    resetAllFilter: 'Réinitialiser tous les filtres',
    reset: 'Réinitialiser',
    apply: 'Appliquer',
    fromText: 'De',
    to: 'À',
  },
  postListPage: {
    price: 'Prix',
    category: 'Catégorie',
    pricing: 'Tarification',
    zeroState: 'Aucun résultat à afficher',
    noFilterResult: 'Aucun résultat avec vos choix de filtre',
    clearAll: 'Tout effacer',
    sellingMethod: 'Méthode de vente',
    sliderSectionTitle: 'Publications en vedette',
    filterResult: (count) => {
      if (count === 1) return `1 Publication trouvée`;
      if (count > 100) return `100+ Publications trouvées`;
      return `${count.toNumberFormat()} Publications trouvées`;
    },
    filterChipsSeeMore: 'Voir plus',
    filterChipsSeeLess: 'Voir moins',
    sorting: {
      lowToHigh: `Prix : du plus bas au plus élevé`,
      highToLow: `Prix : du plus élevé au plus bas`,
      newlyAdded: `Nouvellement ajouté`,
      sellerRating: `Évaluation du vendeur`,
      endingSoonest: `Se termine bientôt`,
    },
    keys: {
      category: 'Catégorie',
      price: 'Prix',
      sellingMethod: 'Méthode de vente',
      condition: 'État',
      secured: 'Sécurisé par Smarkt',
    },
  },
  searchPage: {
    zeroState: `Aucun résultat avec votre recherche`,
    resultFor: (keyword) => `Résultat de la recherche pour "${keyword}"`,
  },
  searchResult: (count) => {
    if (count === 1) return `1 Résultat`;
    if (count > 100) return `100+ Résultats`;
    return `${count.toNumberFormat()} Résultats`;
  },
  httpErrorMsg: {
    somethingWrong: "Quelque chose s'est mal passé",
    notFound: 'Non trouvé',
    connectionError: "Vous n'êtes pas connecté à Internet",
    unauthorized: 'Non autorisé',
    noRefreshToken: 'Pas de jeton de rafraîchissement',
    pleaseTryAgain: 'Erreur interne du serveur, veuillez réessayer plus tard',
  },
  notFoundPage: {
    title: "Ooops, cette page n'a pas été trouvée",
    description:
      "Nous sommes vraiment désolés, mais la page que vous avez demandée est manquante... Peut-être pouvons-nous vous aider ou revenir à la page d'accueil",
    backToHome: "Retour à la page d'accueil",
    contactUs: 'Contactez-nous',
  },
  reportAbuseComponent: {
    reportAbuse: 'Signaler un abus',
  },
  createPostButton: {
    createAuctionTitle: 'Créer une enchère',
    createDirectSaleTitle: 'Créer une vente directe',
    createAuctionSubtitle: 'Prix du marché le plus élevé',
    createDirectSaleSubtitle: "Votre propre prix d'achat immédiat",
    mazadatValetText: 'Smarkt Valet',
    mazadatValetLabel: 'Nouveau',
  },
  smallPostTimerComponent: {
    ends: 'Se termine',
    inText: 'dans',
    expired: 'Expiré',
    timer3MinsRuleText: 'Dernière chance',
  },
  postDetailsPage: {
    productDescription: 'Description du produit',
    questionsAndAnswers: 'Questions & Réponses',
    loadMore: 'Charger plus',
    aboutSeller: 'À propos du vendeur',
    securedBy: 'Sécurisé par',
    dontMissOut: 'Ne manquez pas ça !',
    willExpireIn: 'Expirera dans',
    days: 'Jours',
    hours: 'Heures',
    minutes: 'Minutes',
    seconds: 'Secondes',
    material: 'Matériel',
    buyNow: 'Acheter maintenant',
    bidNow: 'Enchérir maintenant',
    buyFor: 'Acheter pour',
    youMadePurchase: 'Vous avez acheté cet article le',
    itemsAreRemaining: 'articles restants',
    yourBidIsLeading: 'Votre offre est en tête !',
    noDescription: "Il n'y a pas encore de description !",
    noQuestionsAndAnswers: "Il n'y a pas encore de questions et réponses",
    zeroState: 'Pas encore de critiques !',
    loadMoreQuestions: 'Charger plus de questions',
    itemsSoon: 'Articles bientôt',
    soldOut: 'épuisé !',
    itemSoldOut: 'Cet article est épuisé',
    thisPostNotAvailable: "Ce post n'est pas disponible",
    notSecured: 'Vendeur-Acheteur Direct',
    securedItemMobileView: 'Cet article est sécurisé par Smarkt',
    zoomImage: "Passez sur l'image pour zoomer",
    pickupIs: 'Le retrait est',
    deliverBy: 'Livré par',
    pickupNotAvailable: 'non disponible',
    pickupAvailable: 'disponible',
    mazadat: 'Smarkt',
    reply: 'Répondre',
    maxQtyExceededText: 'Quantité maximale dépassée !',
    differentCountryState: {
      title: "Ooops, ce post n'est pas disponible dans votre pays.",
      description:
        "Nous sommes vraiment désolés, mais le post que vous venez d'ouvrir n'est pas disponible dans votre pays... Peut-être pouvons-nous vous aider ou revenir à la page d'accueil",
    },
    brandInfoComponent: {
      category: 'Catégorie',
      subCategory: 'Sous-catégorie',
      warranty: 'Garantie',
      endTime: 'Heure de fin',
    },
    actions: {
      postSimilarButton: 'Publier similaire',
      shareButton: 'Partager',
    },
    bid: (number) => {
      if (number === 1) {
        return `1 Enchère`;
      } else {
        return `${number.toNumberFormat()} Enchères`;
      }
    },
    viewAnswers: 'Voir les réponses',
    reviewZeroState: 'Pas encore de critiques !',
    viewReviews: 'Voir les critiques',
    tabToExpand: 'Tapez pour agrandir',
    addReview: 'Ajouter un avis',
    comments: 'Commentaires',
    totalComments: 'Nombre total de commentaires',
    viewReplies: (number) => {
      if (number === 1)
        return `Voir ${number.toNumberFormat()} réponse précédente...`;
      else return `Voir ${number.toNumberFormat()} réponses précédentes...`;
    },
    hideReplies: 'Masquer les réponses',
    whatAboutThose: 'Que diriez-vous de ceux-ci?',
    cantFetchSection: `Impossible d'afficher les données de la section`,
    backToHome: 'Retour à la maison',
    reportAProblem: 'Signaler un problème',
    cancel: 'Annuler',
    viewAllItems: 'Voir tous les articles',
  },
  widgets: {
    securedItem: 'Cet article est sécurisé par Smarkt',
    securedItemTooltip:
      'Accédez aux points de service de Smarkt pour finaliser vos commandes en toute sécurité',
    notSecured: 'Vendeur-Acheteur Direct',
    secure: '100% Sécurisé',
    payments: 'Méthodes de paiement variées',
    delivery: 'Livraison de produit',
    auctionOrDirectSale: 'Enchère ou vente directe',
    servicePointsNetwork: 'Réseau de points de service extensible',
    winWinComissions: 'Commissions gagnant-gagnant',
    digitalInvoice: 'Facture numérique',
    activeItems: 'Publications actives de ',
    viewAllItems: 'Voir tous les articles',
    secureDes:
      "Avec Smarkt, vous n'êtes exposé à aucun risque de vol, de harcèlement ou de menace.",
    deliveryDes:
      "Avec Smarkt, vous pouvez faire livrer votre commande chez vous, n'importe où, n'importe quand.",
    paymentsDes:
      'Vous pouvez choisir de payer facilement via le portefeuille numérique de Smarkt, par carte de crédit, en plusieurs fois ou en espèces.',
    auctionOrDirectSaleDes:
      'Sur Smarkt, vous pouvez choisir de vendre vos articles par enchère, par vente directe à un prix fixe, ou via un format hybride des deux.',
    servicePointsNetworkDes:
      "Aux points de service de Smarkt, les vendeurs et les acheteurs peuvent inspecter les produits avant de finaliser l'achat sans avoir à s'inquiéter de rencontrer ou de faire confiance à des étrangers.",
    winWinComissionsDes:
      'Vous payez notre commission uniquement lorsque vous vendez vos produits. Créez autant de publications gratuites que vous le souhaitez, et lorsque vous vendez, vous êtes payé.',
    digitalInvoiceDes:
      'Avec Smarkt, vous pouvez générer vos factures numériques pour vos articles vendus, en toute sécurité et commodité.',
    learnMore: 'En savoir plus',
  },
  biddingHistorySidebar: {
    viewHistory: "Voir l'historique",
    biddingHistory: 'Historique des enchères',
    biddingPrice: "Prix de l'enchère :",
    bids: 'Enchères',
    bidders: 'Enchérisseurs',
    highestBid: 'Enchère la plus élevée',
    noBidsAvailable: 'Aucune enchère disponible',
    beTheFirstToBid: 'Aucune enchère pour le moment ! Soyez le premier.',
    lastBid: 'Dernière enchère',
    nextBid: 'Prochaine enchère',
    bidNow: 'Enchérir maintenant',
  },
  header: {
    shopByCategory: 'Magasiner par catégorie',
    createPost: 'Créer une publication',
    becomeBusiness: 'Devenir une entreprise ?',
  },
  footer: {
    safePayments: 'Vous pouvez payer via',
    allRights: 'Tous droits réservés.',
    mazadatInc: '©Smarkt SAE.',
    contactInfo: 'Informations de contact',
    customerService: 'Service client',
    termsConditions: 'Termes et conditions',
    contactUs: 'Contactez-nous',
    privacyPolicy: 'Politique de confidentialité',
    downloadApp: 'Téléchargez notre application',
    mazadat: 'Smarkt',
    aboutUs: 'À propos de nous',
    howITWorks: 'Comment ça marche',
    becomeBusiness: 'Devenir une entreprise?',
  },
  footerMobile: {
    downloadApp: 'Téléchargez notre application',
  },
  sharePopup: {
    shareThisPost: 'Partager ce post',
    copyLink: 'Ou copier le lien',
    copiedToClipboard: 'Copié dans le presse-papier',
    copy: 'Copier',
  },
  aboutSellerView: {
    verifiedSeller: 'Vendeur vérifié',
    viewProfile: 'Voir le profil du vendeur',
    totalRatingAsSeller: 'Évaluations totales en tant que vendeur',
    showReviews: 'Voir les avis',
    hideReviews: 'Masquer les avis',
    reviewsIn: 'Avis au cours des derniers',
    soldItems: (number) => {
      if (number < 1) return `Membre depuis`;
      else if (number > 1) return `articles depuis`;
      else return 'article depuis';
    },
    sold: 'Vendu',
    outOf: 'sur',
    chatWithSeller: 'Discuter avec le vendeur',
    loadMoreReview: "Charger plus d'avis",
    addReview: 'Ajouter un avis',
    zeroState: "Pas encore d'avis!",
  },
  ratingView: {
    commitment: 'Engagement',
    communication: 'Communication',
    speedAndDelivery: 'Vitesse et livraison',
    overallExperience: 'Expérience globale',
    totalRatingText: 'Évaluations totales',
    viewDetails: 'Voir les détails',
  },
  pagerButtonComponent: {
    next: 'Suivant',
    previous: 'Précédent',
  },
  postCard: {
    qtyText: (quantity, bids, discount) => {
      const number = quantity.toNumberFormat();
      if (discount === 'amount') return '';
      if (bids > 0 || !!discount) return `${number} Restant(s)`;
      if (quantity === 1) return `Seulement 1 article disponible`;
      if (quantity > 1) return `${number} articles disponibles`;
      if (quantity === 0) return 'Aucun article disponible';
      return '';
    },
    bidsNumberText: (bids, discount) => {
      if (discount === 'amount') return '';
      if (bids === 1) return `1 Enchère`;
      if (bids > 1) return `${bids.toNumberFormat()} Enchères`;
      return ``;
    },
    discountValuePrefix: 'Économisez',
    off: 'DE RÉDUCTION',
    bidNow: 'Enchérir maintenant',
    buyNow: 'Acheter maintenant',
    soldOut: 'Épuisé!',
    notAvailableViewComponent: {
      notAvailable: 'Non disponible',
    },
  },
  homePage: {
    title: "Restez à l'écoute ! La page d'accueil sera bientôt lancée !",
    description:
      'Vous pouvez rechercher des articles ou utiliser le menu pour parcourir Smarkt',
    featuredPostsTitle: 'Publications en vedette',
    topCategoriesTitle: 'Catégories principales',
    startingFromValue: '100 EUR',
    dayDeal: {
      title: 'Offre du jour',
      dontMissOutWillExpiresIn: 'Ne manquez pas ça ! Ce post expire dans',
      discoverMore: 'Découvrir plus',
      qtyText: (value) => {
        if (value === 1) return `Seulement 1 article restant`;
        if (value > 1) return `${value.toNumberFormat()} articles restants`;
        if (value === 0) return 'Aucun article restant';
        return '';
      },
    },
    comingSoon: {
      title: "Restez à l'écoute pour de superbes offres de réduction !",
      weWillNotifyIn: 'Nous vous informerons dans',
      downloadAppGetLatest:
        "Téléchargez l'application et obtenez les dernières offres !",
    },
  },
  cookies: {
    siteCookies: 'Hey ! Ce site utilise des cookies.',
    cookiesDescription:
      'Nous utilisons des cookies pour améliorer votre expérience sur ce site.',
    acceptCookies: `D'accord Cookie !`,
  },
  megaMenu: {
    close: 'Fermer',
  },
  downloadPopup: {
    content:
      'Téléchargez notre application pour utiliser cette fonctionnalité.',
    close: 'Fermer',
    downloadAppNow: 'Téléchargez Smarkt maintenant !',
  },
  sendMessageForm: {
    title: 'Contactez-nous !',
    description: `Nous croyons que de bonnes conversations suscitent de grandes idées !`,
    fullName: 'Prénom, deuxième prénom & nom de famille',
    nameErr: 'Nom',
    subjErr: 'Objet',
    mobileErr: 'Numéro de mobile',
    emailAddress: 'Entrez votre adresse e-mail',
    emailAddressErr: 'adresse e-mail',
    mobileNumber: 'Entrez votre numéro de mobile',
    subject: 'Entrez votre objet',
    message: 'Veuillez taper votre message',
    submitMessage: 'Soumettre le message',
    closeNow: 'Fermer',
    thankYou: 'Merci !',
    characters: 'Caractères',
    successMessageDescription:
      'Votre message a été envoyé avec succès. Notre équipe vous répondra dès que possible.',
  },
  contactUsPage: {
    weAreHere: {
      title: `Contactez-nous !`,
      description: `Partagez vos pensées avec nous !`,
      headOffice: 'Siège social',
      nasrCity: 'Nasr City',
      nasrCityAddress: '17 Mostafa EL-Nahaas Nasr City, Le Caire',
      phoneNumber: 'Numéro de téléphone',
      mobileNumber: 'Numéro de mobile',
    },
    letUsTalk: {
      title: `Avez-vous besoin de plus d\'aide ?`,
      shareExcitement: 'Partagez votre expérience avec nous !',
      sendInquiry: 'Ou envoyez-nous votre demande',
    },
    ourServicePoints: {
      title: 'Nos points de service',
      description: 'Nous sommes toujours là pour vous !',
      viewMap: 'Voir la carte',
      locationOnMaps: 'Emplacement sur les cartes',
      workingHours: "Horaires d'ouverture",
      viewOnMap: 'Voir sur Google Maps',
      holiday: 'Vacances',
    },
    contactFromHeart: {
      title: 'Restez en contact !',
      description: `Suivez-nous !`,
    },
  },
  getHelpComponent: {
    title: `Avez-vous besoin de plus d'aide ?`,
    shareExcitement: 'Partagez votre expérience avec nous !',
    sendInquiry: 'Ou envoyez-nous votre demande',
  },
  textFieldComponent: {
    characters: 'Caractères',
    optionalLabel: 'Optionnel',
    password: 'Mot de passe',
  },
  phoneNumberComponent: {
    phoneNumber: 'Numéro de mobile',
    code: 'Code',
    selectCode: 'Sélectionner le code',
  },
  registrationForm: {
    joinUs: 'Rejoignez-nous',
    byContinuing: (width, screenSize) => {
      if (width <= screenSize) {
        return 'En poursuivant, vous acceptez les conditions de Smarkt';
      } else {
        return 'En continuant, vous acceptez nos';
      }
    },
    termsOfService: (width, screenSize) => {
      if (width <= screenSize) {
        return "Conditions d'utilisation";
      } else {
        return 'Termes et conditions';
      }
    },
    registeredBusiness: 'Entreprise enregistrée',
    invitationCode: "Code d'invitation",
    verify: 'Vérifier',
    phoneNumber: 'Numéro de mobile',
    code: 'Code',
    username: "Nom d'utilisateur",
    enterUsername: "Entrez votre nom d'utilisateur",
    password: 'Mot de passe',
    passwordCharacters: 'Entrez votre mot de passe',
    title: (width, screenSize) => {
      if (width <= screenSize) {
        return 'Commençons';
      } else {
        return "Si l'opportunité ne frappe pas, construisez une porte.";
      }
    },
    successSubmission:
      "Vous vous êtes inscrit avec succès ! Téléchargez l'application maintenant !",
    businessTooltip:
      "Inscrivez-vous en tant qu'utilisateur professionnel et profitez de l'expérience unique de commerce électronique de Smarkt pour développer votre entreprise.",
    invitationCodeTooltip:
      "Ajoutez votre code d'invitation ici si vous êtes invité par un utilisateur.",
    userNameCheckList: {
      usernameCharLength: '3 à 30 caractères (lettres ou chiffres).',
      noSpecialChar: 'Aucun caractère spécial au début ou à la fin.',
      noSpaces: "Pas d'espaces.",
      noUpperCase: 'Pas de majuscules (CAPS).',
      lessThanSix: 'Maximum 5 chiffres.',
    },
    invitationCodePlaceHolder: "Entrez le code d'invitation",
    signUp: "S'inscrire",
    signIn: 'Se connecter',
    charAnd: '&',
    codeError: 'Veuillez sélectionner un code',
    selectCode: 'Sélectionner le code',
    alreadyAccount: 'Vous avez déjà un compte ?',
    privacyPolicy: 'Politique de confidentialité',
  },
  validationErrorMsg: {
    required: (name) => `${name} est requis`,
    min: (name, min) => `${name} doit contenir au moins ${min} caractères`,
    max: (name, max) => `${name} doit contenir au maximum ${max} caractères`,
    invalid: (name) => `${name} est invalide !`,
    nameError: "Nom d'utilisateur non valide, veuillez vérifier les règles",
    confirmPassword: 'Les mots de passe ne correspondent pas',
  },
  otpForm: {
    title: 'Vérifiez votre numéro de mobile.',
    enterTheCode: 'Veuillez entrer le code à chiffres',
    changeNumber: 'Changer de numéro',
    notReceiveCode: 'Code non reçu ?',
    resendIn: 'Renvoyer dans',
    resendButton: 'Renvoyer',
    verify: 'Vérifier',
    enterTheCodeSentToEmail: 'Entrez le code envoyé à votre email',
    changeEmail: "Changer d'email",
    otpInfo: 'Entrez le code à 4 chiffres qui vous a été envoyé par mobile :',
    whatsapp: 'Ou via WhatsApp',
    successSubmission:
      "Vous vous êtes inscrit avec succès ! Téléchargez l'application maintenant et découvrez les fonctionnalités de Smarkt.",
    whatsappCodeSentSucess: 'Code envoyé avec succès à WhatsApp',
  },
  passwordStrength: {
    weakPassword: 'Mot de passe faible !',
    averagePassword: 'Mot de passe moyen !',
    greatPassword: 'Excellent mot de passe !',
  },
  breadCrumb: {
    home: 'Accueil',
    editPost: 'Modifier la publication',
    similarPost: 'Publication similaire',
    sharePost: 'Partager la publication',
    addToWatchList: 'Ajouter à la liste de suivi',
    reportAbuse: 'Signaler un problème',
    privacyPolicy: 'Politique de confidentialité',
    termsAndConditions: 'Termes et conditions',
    productPolicy: 'Politique de produit',
    businessRegistrationPolicy:
      "Politique d'inscription des utilisateurs professionnels",
    feesPolicy: 'Politique de frais',
    aboutUs: 'À propos de nous',
    howItWorks: 'Comment ça marche',
    categories: 'Catégories',
    sellerProfile: 'Profil du vendeur',
    comingSoon: 'Bientôt disponible',
    notFound: 'Non trouvé',
    contactUs: 'Contactez-nous',
  },
  bottomMenu: {
    profile: 'Profil',
    search: 'Recherche',
    categories: 'Catégories',
    home: 'Accueil',
  },
  searchView: {
    searchFieldPlaceHolder: `Je cherche...`,
  },
  aboutUsPage: {
    shoppingExperience: {
      title:
        "Chez Smarkt, nous créons des parcours pour les acheteurs et les vendeurs vers une expérience d'achat unique",
      description: `Nous sommes la première plateforme de marché X2X et de vente aux enchères au Moyen-Orient et en Afrique, offrant une expérience d'achat unique à ses utilisateurs ; où les particuliers (C2C) et les entreprises de différentes tailles (B2C) peuvent vendre leurs articles et produits. Les utilisateurs peuvent vendre des articles dans des enchères à durée limitée, des listes à prix fixe "Achetez maintenant", ou un format hybride, tandis que les acheteurs profitent de la variété des offres combinées en un seul endroit.`,
      scroll: 'défiler',
    },
    innerSections: {
      onlineAndOffline: 'Relier les mondes en ligne et hors ligne',
      onlineAndOfflineDesc: `En reliant les mondes en ligne et hors ligne, Smarkt a innové un réseau de points de service physiques décentralisés sans précédent qui est l'épitome d'un modèle "sans confiance", où toutes les parties ; particuliers, commerçants, acheteurs et vendeurs, n'ont pas besoin de faire confiance à des inconnus/la seconde partie. Ce modèle unique soutient les marchands et fournisseurs, y compris les petites et micro-entreprises, avec plusieurs avantages, tels que l'espace de stockage, la logistique et les exigences de service rapide, leur ouvrant une chaîne d'opportunités infinies pour développer leur entreprise et promouvoir davantage leurs marques.`,
      innovation: `Construire des canaux X2C innovants et durables`,
      innovationDesc: `Chez Smarkt, nous aspirons à construire une communauté pour tous à travers des expériences de commerce électronique évolutives, où chacun peut grandir et prospérer. Nous croyons fermement que les petits efforts peuvent faire une grande différence, c'est pourquoi nous sommes enracinés depuis les débuts de Smarkt pour promouvoir l'idée du re-commerce (achat et vente de produits d'occasion), encourageant les acheteurs et les vendeurs à prolonger la durée de vie des produits pour contribuer à une planète plus saine et à une économie verte.`,
      safety: `Offrir commodité et sécurité aux utilisateurs`,
      safetyDesc: `Nous nous efforçons d'améliorer la vie des gens grâce à l'accessibilité des services de produits de Smarkt, associée à la commodité et à la sécurité offertes par le concept de ses points de service uniques à travers le pays, ce qui constitue une situation gagnant-gagnant pour les entreprises de toutes tailles; car ils peuvent profiter d'une variété de services de support logistique, de stockage et de gestion des commandes, et les acheteurs qui peuvent inspecter leurs achats en toute sécurité et anonymement.`,
      safetyDescSub: `Offrant une variété de solutions de paiement numérisées, Smarkt promet à ses clients des achats accessibles et pratiques via sa plateforme et des options de paiement en plusieurs fois.`,
    },
    mazadatValues: {
      sectionTitle: `Valeurs de Smarkt`,
      firstSlide: {
        title: 'Innovation',
        desc: "Notre modèle commercial inventé offre à nos utilisateurs une expérience de vente au détail unique. Notre système d'application utilise les dernières technologies pour organiser une expérience d'achat sans tracas, qui est à portée de clic.",
      },
      secondSlide: {
        title: 'Sécurité',
        desc: "Notre plateforme de marché est entièrement axée sur la sécurité et la confiance dans un monde sans confiance. Nous fournissons un service d'entiercement, qui garantit la satisfaction tant de l'acheteur que du vendeur.",
      },
      thirdSlide: {
        title: 'Inclusivité',
        desc: "Chaque utilisateur peut accéder à notre plateforme, quelle que soit sa langue, son emplacement, sa situation financière et ses besoins de style de vie. L'inclusivité et l'accessibilité de Smarkt se reflètent le mieux dans sa structure multilingue, les multiples options de paiement et les options d'expédition flexibles pour répondre à des besoins divers.",
      },
      fourthSlide: {
        title: 'Durabilité',
        desc: "Nous promouvons l'idée du re-commerce; encourageant les acheteurs et les vendeurs à prolonger la durée de vie des articles de seconde main. Cela contribue à la réduction des déchets et à la conservation des ressources.",
      },
      fifthSlide: {
        title: 'Collaboration',
        desc: 'Nous aspirons à construire une communauté pour tous à travers une expérience de commerce électronique évolutive. Que vous soyez un étudiant, une mère ou un fournisseur cherchant à élargir votre portée, nous vous invitons à rejoindre notre communauté et à collaborer avec nous.',
      },
    },
    ourServicesPoint: {
      title: `Nos points de service`,
      servicePoints: {
        heliopolis: 'Heliopolis',
        el_Mohandseen: `El Mohandseen`,
        nasrCity: 'Nasr City',
        maadi: 'Maadi',
      },
    },
    subscription: {
      title: `Nous aimerions en savoir plus sur votre entreprise`,
    },
  },
  howItWorksPage: {
    header: {
      smartAndSafe: 'intelligent. sûr.',
      convenient: 'pratique',
      bidBuyAndSell: 'enchérir, acheter & vendre',
      description:
        'Avec un seul compte sur Smarkt, vous pouvez enchérir, acheter et vendre',
    },
    fiveProducts: {
      title: '5 différents états de produits',
      newText: 'Neuf',
      mint: 'Comme neuf',
      used: 'Utilisé',
      defective: 'Défectueux',
      openBox: 'Boîte ouverte',
    },
    checkOutMazadat: {
      title: `Consultez les annonces de Smarkt dans plus de 35 catégories`,
    },
    forgetAboutScam: {
      title: 'Oubliez les arnaques ! sécurisé par Smarkt',
      description:
        'Un réseau de points de service extensible où vous pouvez acheter et vendre en toute sécurité, commodité et confidentialité !',
    },
    payment: {
      title: `Options de paiement diversifiées et sécurisées`,
    },
    mazadatInNumbers: {
      title: `Smarkt en chiffres`,
      circles: {
        download: 'Secondes pour télécharger',
        auctions: 'Secondes pour lancer une enchère ou une vente directe',
        dropOff: 'Heures autorisées pour déposer ou récupérer votre article',
        returnRequest: "Période d'escrow pour déposer une demande de retour",
        sellerBuyer: 'Protection acheteur-vendeur',
        conditions: 'Différents états de produit',
        increasePosts:
          'Plans promotionnels pour augmenter les ventes de votre publication',
      },
    },
    whySecured: {
      cards: {
        pickup: 'Dépôt & Retrait au point de service',
        inspect: 'Inspecter avant de récupérer un article',
        deliveryService: 'Services de livraison et de stockage disponibles.',
        returnRequest:
          'Fenêtre de 48 heures pour déposer une demande de retour',
      },
      title: 'Pourquoi "Sécurisé par Smarkt"',
    },
    shopSmart: {
      title: 'Achetez malin tous les jours',
    },
  },
  categoriesPage: {
    favouriteCategories: 'Catégories préférées',
    allCategories: 'Toutes les catégories',
    searchPlaceholder: 'Rechercher dans les catégories',
    noFavouriteSelected:
      "Appuyez sur l'icône en forme de cœur pour ajouter à vos favoris",
  },
  sellerProfilePage: {
    showContact: 'Afficher le contact',
    shareProfile: 'Partager le profil',
    reviews: 'Avis',
    activePost: 'Publications actives',
    sellerReviews: 'Avis des vendeurs',
    outOf: 'sur',
    reportAbuse: 'Signaler un problème',
    viewReviews: 'Voir les avis',
    zeroState: "Pas d'avis",
    noActivePosts: 'Aucune publication active à afficher',
    newToOld: 'Du plus récent au plus ancien',
    oldToNew: 'Du plus ancien au plus récent',
    lowToHigh: 'Note la plus basse au plus élevé',
    highToLow: 'Note la plus élevée au plus basse',
  },
  underConstructionPage: {
    title: `Oh ! Désolé, cette page est en cours de construction.`,
    moreText:
      'En attendant, vous pouvez télécharger notre application pour profiter de toutes les fonctionnalités de Smarkt',
  },
  downloadAppFooter: {
    title: 'Rejoignez-nous maintenant & explorez le monde de Smarkt',
    description: 'Téléchargez notre application ici',
  },
  serviceWorker: {
    installedMessage: "L'application a été installée",
    updateMessage: 'Il y a une nouvelle mise à jour, veuillez actualiser',
  },
  countrySelect: {
    title: 'Sélection du pays',
    apply: 'Appliquer',
  },
};

export default fr;
