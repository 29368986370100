/**
 * Please file all your z-index values in here
 * and sort them correctly by value.
 */

export const z_index_0 = 0; //default
export const z_index_1 = 100; //use this for any layer above the default
export const z_index_2 = 200;
export const z_index_3 = 300;
export const z_index_4 = 400;
export const z_index_5 = 500;
export const z_index_6 = 600;
