import { useEffect, useState } from 'react';
import { formateSmallTimer } from '../app/helper/formaters';

const useCountdown = (targetDate) => {
  // @TODO: add feedback when user reconnect.
  const [countDown, setCountDown] = useState(formateSmallTimer(targetDate));
  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(formateSmallTimer(targetDate));
    }, 1000);
    /*
      will not start counter unless
      the remaining time is less than 70 minutes
    */
    if (!countDown.isCountDown) clearInterval(interval);
    return () => clearInterval(interval);
  }, [countDown]);
  return countDown;
};

export { useCountdown };
