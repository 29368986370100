import AccountDropdownItem from '../accountDropdownItem/accountDropdownItem';
import { translate } from '../../../app/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../redux/actions/authActions';
import services from '../../../services';
import { useNavigate } from 'react-router-dom';

function AccountDropdown({ closeDropdown }) {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const user = services.storageService.get('user');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = async () => {
    if (isAuthenticated) {
      dispatch(logout(user.logoutToken));
      if (closeDropdown) closeDropdown();
    } else {
      navigate('/login');
    }
  };

  return (
    <>
      {isAuthenticated && (
        <AccountDropdownItem
          title={translate('accountDropdown.logout')}
          icon={'icon-logout'}
          onClick={() => handleClick()}
        />
      )}
    </>
  );
}

export default AccountDropdown;
