import { translate } from '../../../app/i18n';

export default function OldPriceWithDiscount({
  price,
  discount_value,
  discount_unit,
  themeColor,
  size,
  showOldPriceWithPercentage,
  showOldPriceWithValue,
}) {
  //@TODO : add prop type
  const displayPrice = price.toNumberFormat();
  const displayDiscount = discount_value.toNumberFormat();

  return (
    <div
      className={`discount-small-view discount-small-view--${size} discount-small-view--${themeColor}`}
    >
      {discount_unit === 'percentage' ? (
        <p className={'old-price-percentage-con'}>
          {showOldPriceWithPercentage && (
            <del className="discount-small-view__old-price">{displayPrice}</del>
          )}
          <span className="discount-small-view__percentage">
            {displayDiscount}%<sup>{translate('postCard.off')}</sup>
          </span>
        </p>
      ) : (
        <p className="discount-small-view__value">
          {showOldPriceWithValue && (
            <del className="discount-small-view__old-price">{displayPrice}</del>
          )}
          <span className="discount-small-view__value__save">
            {translate('postCard.discountValuePrefix')}
          </span>
          <span className="discount-small-view__value__label">
            <sup className="discount-small-view__value__currency">
              {'EUR' || ''}
            </sup>
            <span>{displayDiscount}</span>
          </span>
        </p>
      )}
    </div>
  );
}
