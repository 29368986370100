import { Button } from '../../atoms';
import SearchInput from '../searchInput/SearchInput';
import { useDispatch, useSelector } from 'react-redux';
import { translate } from '../../../app/i18n';
import StorageService from '../../../services/StorageService';
import { useState } from 'react';
import { updateUserCountry } from '../../../redux/actions/userAction';
import { updateLanguage } from '../../../app/helper/general';
import { EUcountries } from '../../../constants/general';

export default function SelectCountry({
  setOpen,
  selectedIndex,
  setSelectedIndex,
}) {
  const { websiteLanguage } = useSelector((state) => state.user);
  const [selectedKey, setSelectedKey] = useState('');
  const [search, setSearch] = useState('');
  const countries = EUcountries;
  const dispatch = useDispatch();
  const pathname = window.location.pathname;
  const searchQuery = new URLSearchParams(window.location.search);

  function selected(keyToFind) {
    return countries.findIndex(
      (country) => country.countryISOCode === keyToFind,
    );
  }

  const handleClick = (key) => {
    setSelectedKey(key);
    setSelectedIndex(selected(key));
  };

  const handleSubmit = () => {
    dispatch(updateUserCountry({ country: selectedKey }));
    StorageService.set('countryKey', selectedKey);
    setOpen(false);
    updateLanguage(selectedKey?.toLowerCase(), pathname, searchQuery);
    setTimeout(() => {
      window.location.reload(true);
    }, 1000);
  };

  function filterCountriesByLanguageAndSearch() {
    return countries.filter((country) =>
      country.countryName[websiteLanguage]
        ?.toLowerCase()
        .includes(search.toLowerCase()),
    );
  }

  const filteredCountries = filterCountriesByLanguageAndSearch();

  return (
    <div className="select-country">
      <span className="select-country_title">
        {translate('countrySelect.title')}
      </span>
      <hr />
      <div className="select-country_search">
        <SearchInput onChange={setSearch} value={search} />
      </div>
      {filteredCountries.map((country, index) => {
        return (
          <div
            onClick={() => handleClick(country.countryISOCode)}
            className="select-country_item"
            key={index}
          >
            <div className="select-country_item_wrapper">
              <img
                className="select-country_item_img"
                src={country.countryFlag}
              />
              <span className="select-country_item_name">
                {country.countryName[websiteLanguage]}
              </span>
            </div>
            {selectedIndex === index && (
              <span className="select-country_item_icon icon-Verified"></span>
            )}
          </div>
        );
      })}
      <div className="select-country_button">
        <Button
          onClick={() => handleSubmit()}
          disabled={selectedKey ? false : true}
          type="submit"
        >
          {translate('countrySelect.apply')}
        </Button>
      </div>
    </div>
  );
}
