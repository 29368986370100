import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SearchForm from './searchForm';
import CreatePost from './createPost/createPost';
import IconsHeader from './iconsHeader/iconsHeader';
import { Link, useNavigate } from 'react-router-dom';
import events from '../../../../../utils/events';
import { LogoAssets } from '../../../../../assets/assetProvider';

const SearchHeader = ({
  closeOverlayHandler,
  showSearch,
  showNotifications,
  showUserProfile,
  showSignUp,
}) => {
  const { websiteLanguage } = useSelector((state) => state.user);
  const [showOverLay, setShowOverLay] = useState(false);
  const [showCreatePostOptions, setShowCreatePostOptions] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [searchDropDownContent, setSearchDropDownContent] = useState({
    showSearchResults: false,
    showLoading: false,
    showSearchDropDown: false,
  });
  const [showLangMenu, setShowLangMenu] = useState(false);
  const mobileContainerRef = useRef(); // mobile search group contrainer
  const searchIconRef = useRef(); // mobile search icon
  const navigate = useNavigate();

  const onHandleCloseOverlay = () => {
    // when close overlay on mobile
    // hide search group ( input & dropdown)
    // hide overlay
    // change search icon to white color
    mobileContainerRef && mobileContainerRef.current.classList.add('d-none');
    showOverLay && setShowOverLay(false);
    searchIconRef && searchIconRef?.current?.classList?.remove('text-primary');
    // hide dropdown for both mobile and web & hide search results
    // hide create post dropdown if it's opened
    setSearchDropDownContent({
      ...searchDropDownContent,
      showSearchDropDown: false,
      showLoading: false,
    });
    setShowCreatePostOptions(false);
    setShowLangMenu(false);
    setShowAccountMenu(false);
  };

  const handleSearch = (searchInput) => {
    onHandleCloseOverlay();
    navigate({
      pathname: `/search`,
      search: `keyword=${encodeURIComponent(searchInput)}`,
    });
    events.logSearch(searchInput);
  };

  return (
    <>
      <div className="search-header">
        <Link className="logo-navigation" to={'/'}>
          <img
            loading="lazy"
            className="mazadat-logo-header"
            alt="Mazadat"
            src={LogoAssets.mazadatLogos[websiteLanguage]}
          />
        </Link>
        {/* search web view structure */}
        {showSearch && (
          <SearchForm
            {...{
              handleSearch,
              closeOverlayHandler,
              onHandleCloseOverlay,
              setShowOverLay,
              setShowCreatePostOptions,
              searchDropDownContent,
              setSearchDropDownContent,
            }}
            className="d-web"
          />
        )}
        <div className="icons-wrapper">
          {/* shown only on web */}
          <div className="d-web">
            <CreatePost
              {...{
                setShowOverLay,
                showOverLay,
              }}
            />
          </div>
          {/* search mobile view structure */}
          {showSearch && (
            <SearchForm
              {...{
                handleSearch,
                closeOverlayHandler,
                onHandleCloseOverlay,
                setShowOverLay,
                setShowCreatePostOptions,
                searchDropDownContent,
                setSearchDropDownContent,
                mobileContainerRef,
                searchIconRef,
              }}
              className="d-none search-header__mobile-icon"
            />
          )}

          {/* show on mobile only */}
          <div className="d-none">
            <CreatePost
              {...{
                setShowOverLay,
                setShowCreatePostOptions,
                searchDropDownContent,
                setSearchDropDownContent,
                showCreatePostOptions,
                showOverLay,
              }}
            />
          </div>
          <IconsHeader
            {...{
              onHandleCloseOverlay,
              showLangMenu,
              setShowLangMenu,
              showNotifications,
              showUserProfile,
              showSignUp,
              setShowOverLay,
              showOverLay,
              showAccountMenu,
              setShowAccountMenu,
            }}
            mobileIconClassName="d-none search-header__mobile-icon"
          />
        </div>
      </div>
      {(searchDropDownContent.showSearchDropDown ||
        showOverLay ||
        showCreatePostOptions ||
        showLangMenu ||
        showAccountMenu) && (
        <div onClick={onHandleCloseOverlay} className="searchBar-overlay"></div>
      )}
    </>
  );
};

SearchHeader.propTypes = {
  showSearch: PropTypes.bool,
  showNotifications: PropTypes.bool,
  showUserProfile: PropTypes.bool,
  showSignUp: PropTypes.bool,
};

SearchHeader.defaultProps = {
  showSearch: true,
  showNotifications: true,
  showUserProfile: true,
  showSignUp: false,
};

export default SearchHeader;
