import styled from 'styled-components';
import { breakpoints } from '../../../constants/general';

const getGap = ({ gap }) => {
  if (gap !== undefined) {
    return gap;
  }
  return 10;
};

export const Wrapper = styled.div`
  white-space: nowrap;
  font-size: 0;
  max-width: 100%;
  cursor: pointer;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const Carousel = styled.div`
  white-space: nowrap;
  font-size: 0;
  cursor: pointer;
  display: block;
  overflow: hidden;

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and ${breakpoints.tablet_max_width} {
    overflow-x: scroll !important;
    -webkit-overflow-scrolling: touch;
  }

  & > div[draggable='false'] {
    margin-left: ${(props) => (props.lang !== 'ar' ? getGap(props) : 0)}px;
    margin-right: ${(props) => (props.lang === 'ar' ? getGap(props) : 0)}px;

    &:first-child {
      margin-left: ${(props) => (props.lang !== 'ar' ? 0 : 'initial')};
      margin-right: ${(props) => (props.lang === 'ar' ? 0 : 'initial')};
    }
  }
`;
