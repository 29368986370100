import { translate } from '../app/i18n';
import { routeConstants } from './route.constants';
import { ImageAssets } from '../assets/assetProvider';

export const privacyPolicy = [
  // TODO @Mokhtar move this to string file
  {
    title: translate('legalPages.privacyPolicyTopics.introduction'),
    path: '#introduction',
  },
  {
    title: translate('legalPages.privacyPolicyTopics.topics'),
    path: '#topics',
  },
  {
    title: translate('legalPages.privacyPolicyTopics.collection'),
    path: '#collection',
  },
  {
    title: translate('legalPages.privacyPolicyTopics.purposes'),
    path: '#purposes',
  },
  {
    title: translate('legalPages.privacyPolicyTopics.update'),
    path: '#update',
  },
  {
    title: translate('legalPages.privacyPolicyTopics.personalInfo'),
    path: '#personal-info',
  },
  {
    title: translate('legalPages.privacyPolicyTopics.entities'),
    path: '#entities',
  },
  {
    title: translate('legalPages.privacyPolicyTopics.marketing'),
    path: '#marketing',
  },
  {
    title: translate('legalPages.privacyPolicyTopics.cookies'),
    path: '#cookies',
  },
  {
    title: translate('legalPages.privacyPolicyTopics.protect'),
    path: '#protect',
  },
  {
    title: translate('legalPages.privacyPolicyTopics.general'),
    path: '#general',
  },
];

export const termsAndConditions = [
  {
    title: translate('legalPages.termsAndConditionsTopics.introduction'),
    path: '#introduction',
  },
  {
    title: translate('legalPages.termsAndConditionsTopics.service'),
    path: '#service',
  },
  {
    title: translate('legalPages.termsAndConditionsTopics.fees'),
    path: '#fees',
  },
  {
    title: translate('legalPages.termsAndConditionsTopics.wallet'),
    path: '#wallet',
  },
  {
    title: translate('legalPages.termsAndConditionsTopics.promotions'),
    path: '#promotions',
  },
  {
    title: translate('legalPages.termsAndConditionsTopics.conditions'),
    path: '#conditions',
  },
  {
    title: translate('legalPages.termsAndConditionsTopics.obligations'),
    path: '#obligations',
  },
  {
    title: translate('legalPages.termsAndConditionsTopics.buyandbid'),
    path: '#buyandbid',
  },
  {
    title: translate('legalPages.termsAndConditionsTopics.collectionpoints'),
    path: '#collectionpoints',
  },
  {
    title: translate('legalPages.termsAndConditionsTopics.userRepresentations'),
    path: '#user-representations',
  },
  {
    title: translate('legalPages.termsAndConditionsTopics.registration'),
    path: '#registration',
  },
  {
    title: translate('legalPages.termsAndConditionsTopics.userViolations'),
    path: '#user-violations',
  },
  {
    title: translate('legalPages.termsAndConditionsTopics.eligibility'),
    path: '#eligibility',
  },
  {
    title: translate('legalPages.termsAndConditionsTopics.amendments'),
    path: '#amendments',
  },
  {
    title: translate('legalPages.termsAndConditionsTopics.governingLaw'),
    path: '#governing-law',
  },
  {
    title: translate(
      'legalPages.termsAndConditionsTopics.disputeResolutionForum',
    ),
    path: '#dispute-resolution-forum',
  },
  {
    title: translate('legalPages.termsAndConditionsTopics.limitation'),
    path: '#limitation',
  },
  {
    title: translate(
      'legalPages.termsAndConditionsTopics.warrantiesDisclaimer',
    ),
    path: '#warrenties-disclaimer',
  },
  {
    title: translate('legalPages.termsAndConditionsTopics.customerSupport'),
    path: '#customer-support',
  },
  {
    title: translate('legalPages.termsAndConditionsTopics.indemnity'),
    path: '#indemnity',
  },
  {
    title: translate('legalPages.termsAndConditionsTopics.daydeal'),
    path: '#deydeal',
  },
  {
    title: translate('legalPages.termsAndConditionsTopics.licenses'),
    path: '#licenses',
  },
  {
    title: translate(
      'legalPages.termsAndConditionsTopics.intellectualPropertyRights',
    ),
    path: '#intellectual-property-rights',
  },
  {
    title: translate('legalPages.termsAndConditionsTopics.generalProvisions'),
    path: '#general-provisions',
  },
];

export const productPolicy = [
  {
    title: translate('legalPages.productPolicyTopics.introduction'),
    path: '#introduction',
  },
  {
    title: translate('legalPages.productPolicyTopics.prohibitedProducts'),
    path: '#prohibited-products',
  },
  {
    title: translate('legalPages.productPolicyTopics.restrictedProducts'),
    path: '#restricted-products',
  },
  {
    title: translate('legalPages.productPolicyTopics.violation'),
    path: '#violation',
  },
];

export const businessRegistration = [
  {
    title: translate(
      'legalPages.businessRegistrationTopics.registrationPolicy',
    ),
    path: '#registration-policy',
  },
  {
    title: translate('legalPages.businessRegistrationTopics.businessEntity'),
    path: '#business-entity',
  },
];

export const conditionGuide = [
  {
    title: translate('legalPages.conditionGuideTopics.conditionGuide'),
    path: '#condition-guide',
  },
  {
    title: translate(
      'legalPages.conditionGuideTopics.categoriesAndDefinitions',
    ),
    path: '#categories-and-definitions',
  },
];

export const feesPolicy = [
  {
    title: translate('legalPages.feesPolicyTopics.feesPolicy'),
    path: '#fees-policy',
  },
  {
    title: translate('legalPages.feesPolicyTopics.transactionFees'),
    path: '#transaction-fees',
  },
  {
    title: translate('legalPages.feesPolicyTopics.promotionsFees'),
    path: '#promotions-fees',
  },
  {
    title: translate('legalPages.feesPolicyTopics.storageFees'),
    path: '#storage-fees',
  },
];

export const mainPage = [
  {
    name: 'feesPolicy',
    icon: ImageAssets.feesIllustration,
    path: routeConstants.FEES_POLICY, // TODO @Mokhtar get this from route.constants
  },
  {
    name: 'userAgreement',
    icon: ImageAssets.userAgreementIllustration,
    path: routeConstants.TERMS_CONDITIONS,
  },
  {
    name: 'productPolicy',
    icon: ImageAssets.restrictedProductsIllustration,
    path: routeConstants.PRODUCT_POLICY,
  },
  {
    name: 'businessRegistration',
    icon: ImageAssets.businessRegIllustration,
    path: routeConstants.BUSINESS_REGISTRATION_POLICY,
  },
  {
    name: 'itemCondition',
    icon: ImageAssets.itemConditionIllustration,
    path: routeConstants.ITEM_CONDITION_GUIDE,
  },
];
