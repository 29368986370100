import { useQuery } from '@tanstack/react-query';
import services from '../services';
import { useDispatch, useSelector } from 'react-redux';
import { snackboxOpen } from '../redux/actions/snackboxAction';

export function useGetCountryLanguages() {
  const { websiteCountry } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  return useQuery({
    queryKey: ['countryLanguages', { country: websiteCountry }],
    queryFn: () =>
      services.countryLanguagesService.getCountryLanguages(websiteCountry),
    onError: ({ message }) => {
      dispatch(snackboxOpen(message, 'failure'));
    },
  });
}
