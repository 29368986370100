import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { DownloadPopup, PopoverWrapper } from '../../../../../templates';
import { AccountDropdown } from '../../../../../molecules';
import { routeConstants } from '../../../../../../constants';
import { translate } from '../../../../../../app/i18n';
import { shallowEqual, useSelector } from 'react-redux';
import { getAvatarUrl } from '../../../../../../app/helper/general';
import Loader from 'react-loader-spinner';
import { colors } from '../../../../../../app/theme';
import { IconAssets } from '../../../../../../assets/assetProvider';
import LanguagesDropdown from '../../../../../molecules/languagesDropdown/languagesDropdown';

const IconsHeader = ({
  showNotifications,
  showSignUp,
  setShowOverLay,
  showOverLay,
  showAccountMenu,
  setShowAccountMenu,
  showLangMenu,
  setShowLangMenu,
}) => {
  const [showDownload, setShowDownload] = useState(false);
  const { isAuthenticated } = useSelector((state) => state.auth, shallowEqual);
  const { active } = useSelector((state) => state.watchlistStatus);
  const data = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleCloseDownload = () => {
    setShowDownload(false);
  };
  const handleAccountDropdown = () => {
    if (isAuthenticated) {
      setShowOverLay(!showOverLay);
      setShowAccountMenu(!showAccountMenu);
    } else {
      navigate(routeConstants.LOGIN);
    }
  };
  const handleLangDropdown = () => {
    setShowOverLay(!showOverLay);
    setShowLangMenu(!showLangMenu);
  };

  const setDropdownDisplay = (show) => {
    setShowAccountMenu(show);
    setShowOverLay(show);
    setShowLangMenu(show);
  };

  return (
    <>
      <div className="header-icons">
        <button
          className="button-transparent"
          onClick={() => {
            navigate(routeConstants.WATCHLIST);
          }}
          aria-label="watchList"
        >
          <span className="icon-heart">
            {/** Watchlist Only show red dot when a user is logged in and have an active status **/}
            {isAuthenticated && active && (
              <Loader
                type={'Puff'}
                width={10}
                height={10}
                color={colors.ERROR}
                visible
                className="icon-heart__indicator"
              />
            )}
          </span>
        </button>
        {showNotifications && (
          <button
            className={`button-transparent`}
            onClick={() => {
              navigate(routeConstants.COMING_SOON);
            }}
            aria-label="notification"
          >
            <span className="icon-notification"></span>
          </button>
        )}
        <button
          className="button-transparent"
          onClick={() => {
            navigate(routeConstants.COMING_SOON);
          }}
          aria-label="shopping cart"
        >
          <span className="icon-bag"></span>
        </button>

        <button
          aria-label="language"
          className={`button-transparent`}
          onClick={handleLangDropdown}
        >
          <img src={IconAssets.languageFlags[data.websiteLanguage]} />
          <span className="language-text d-web">
            {data.websiteLanguage.toUpperCase()}
          </span>
        </button>
        <button
          aria-label="profile"
          className={`button-transparent d-web`}
          onClick={handleAccountDropdown}
        >
          {isAuthenticated && data ? (
            <img
              className="login-user-avatar"
              src={getAvatarUrl(data.avatar)}
              alt="avatar"
            />
          ) : (
            <span className="icon-profile"></span>
          )}
        </button>
        <div className="header-icons_profile d-web">
          {showAccountMenu && (
            <PopoverWrapper show={showAccountMenu} setShow={setDropdownDisplay}>
              <AccountDropdown closeDropdown={handleAccountDropdown} />
            </PopoverWrapper>
          )}
        </div>
        <div className="header-icons_language">
          {showLangMenu && (
            <PopoverWrapper show={showLangMenu} setShow={setDropdownDisplay}>
              <LanguagesDropdown />
            </PopoverWrapper>
          )}
        </div>

        {showSignUp && (
          <Link
            className="header-icons__sign-up"
            to={routeConstants.REGISTRATION}
          >
            {translate('registrationForm.signUp')}
          </Link>
        )}
      </div>

      <DownloadPopup show={showDownload} onHide={handleCloseDownload} />
    </>
  );
};

export default IconsHeader;
