import { API } from '../constants';

export default function inquiryService(httpClient) {
  const getUserInquiries = () => {
    return httpClient.get(API.GET_INQUIRIES);
  };

  const postInquiryAnswer = (answerData) => {
    return httpClient.post(API.POST_INQUIRIES_ANSWER, answerData);
  };

  const postInquiryImage = (imageData) => {
    return httpClient.post(API.POST_INQUIRIES_IMAGE, imageData, {
      'content-type': 'multipart/form-data',
    });
  };

  const getInquiryMessages = (questionId) => {
    return httpClient.get(`${API.GET_INQUIRY_MESSAGES}/${questionId}`);
  };

  return {
    getUserInquiries,
    postInquiryAnswer,
    postInquiryImage,
    getInquiryMessages,
  };
}
