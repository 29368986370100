import { translate } from '../app/i18n';
import { ImageAssets } from '../assets/assetProvider';

export const widgetsData = [
  {
    src: ImageAssets.secureIllustration,
    title: translate('widgets.secure'),
    subTitle: translate('widgets.secureDes'),
    path: '',
    alt: translate('widgets.secure'),
  },
  {
    src: ImageAssets.auctionIllustration,
    title: translate('widgets.auctionOrDirectSale'),
    subTitle: translate('widgets.auctionOrDirectSaleDes'),
    path: '',
    alt: translate('widgets.auctionOrDirectSale'),
  },
  {
    src: ImageAssets.deliveryIllustration,
    title: translate('widgets.delivery'),
    subTitle: translate('widgets.deliveryDes'),
    path: '',
    alt: translate('widgets.delivery'),
  },
  {
    src: ImageAssets.paymentCardIllustration,
    title: translate('widgets.payments'),
    subTitle: translate('widgets.paymentsDes'),
    path: '',
    alt: translate('widgets.payments'),
  },
];
