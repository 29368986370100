import {
  Navs,
  ResultCount,
  ResultCountSmall,
  Root,
  RootSmall,
  SliderContainer,
  SortBy,
  SortIcon,
  SortText,
  TopContainer,
} from './PostSortBar.styled';
import ToggleButton from '../../atoms/toggleButton/ToggleButton';
import { Divider, SwiperItem } from '../../atoms';
import { DropDownButton, Swiper } from '../../molecules';
import { translate } from '../../../app/i18n';
import { useSelector } from 'react-redux';
import {
  breakpoints,
  getPostListKeys,
  postListKeys,
  postSort,
} from '../../../constants/general';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '../../../constants';
import Services from '../../../services';
import { useMediaQuery } from 'react-responsive/src';
import { useEffect, useState } from 'react';
import { IconAssets } from '../../../assets/assetProvider';

function getPreviousIcon(language) {
  return language !== 'ar' ? 'icon-arrow-left' : 'icon-arrow-right';
}

function getNextIcon(language) {
  return language !== 'ar' ? 'icon-arrow-right' : 'icon-arrow-left';
}

/**
 * Post sort bar
 * @returns {JSX.Element}
 * @constructor
 */
function PostSortBar({
  onSortChange,
  sortValue,
  categoryId,
  keyword,
  selectedOptions,
  onOptionSelect,
  filter,
  totalCount,
}) {
  const { websiteLanguage } = useSelector((state) => state.user); // en or ar
  const isMobile = useMediaQuery({ query: breakpoints.mobile_max_width });
  const [swiper, setSwiper] = useState({});

  useEffect(() => {
    if (swiper.slideTo) {
      swiper.slideTo(1);
    }
  }, [swiper.pages]);

  const { data } = useQuery(
    categoryId
      ? queryKeys.getPostFilterFieldsByCategory(categoryId, {})
      : queryKeys.getPostFilterFieldsByKeyword(keyword, {}),
    categoryId
      ? Services.postService.getPostFilterFieldByCategory
      : Services.postService.searchFilterFieldByKeyword,
    { enabled: !!categoryId || !!keyword },
  );

  const getFilterList = (filters) => {
    if (categoryId) {
      return data
        ? filters[getPostListKeys(postListKeys.CATEGORY, websiteLanguage)]
        : [];
    }
    return data
      ? [
          ...filters[
            getPostListKeys(postListKeys.SELLING_METHOD, websiteLanguage)
          ],
          ...filters[getPostListKeys(postListKeys.SECURED, websiteLanguage)],
        ]
      : [];
  };

  const handleSelect = (value) => {
    let values;
    let key;

    if (categoryId) {
      values = [...selectedOptions];
      key = getPostListKeys(postListKeys.CATEGORY, websiteLanguage);
    } else {
      const sellingMethods = data
        ? data.filters[
            getPostListKeys(postListKeys.SELLING_METHOD, websiteLanguage)
          ]
        : [];
      const secured = data
        ? data.filters[getPostListKeys(postListKeys.SECURED, websiteLanguage)]
        : [];
      if (sellingMethods.includes(value)) {
        values = sellingMethods.filter((method) =>
          selectedOptions.includes(method),
        );
        key = getPostListKeys(postListKeys.SELLING_METHOD, websiteLanguage);
      } else {
        values = secured.filter((securedValue) =>
          selectedOptions.includes(securedValue),
        );
        key = getPostListKeys(postListKeys.SECURED, websiteLanguage);
      }
    }

    if (values.includes(value)) {
      values = values.filter((item) => item !== value);
    } else {
      values.push(value);
    }

    if (onOptionSelect) {
      onOptionSelect(key, values);
    }
  };

  return (
    <>
      <Root>
        <ResultCount>{translate(`searchResult`)(totalCount || 0)}</ResultCount>
        <SliderContainer>
          <Swiper spacing={8} handleChange={setSwiper}>
            {getFilterList(data ? data.filters : [])?.map((filter, key) => (
              <SwiperItem key={key} reRender>
                <ToggleButton
                  size={'small'}
                  label={filter}
                  isActive={selectedOptions.includes(filter)}
                  onClick={() => handleSelect(filter)}
                />
              </SwiperItem>
            ))}
          </Swiper>
        </SliderContainer>
        {(swiper.hasPrevious || swiper.hasNext) && (
          <Navs>
            <span
              onClick={swiper.previous}
              className={`${getPreviousIcon(websiteLanguage)} ${
                swiper.hasPrevious ? '' : 'inactive'
              }`}
            />
            <span
              onClick={swiper.next}
              className={`${getNextIcon(websiteLanguage)} ${
                swiper.hasNext ? '' : 'inactive'
              }`}
            />
          </Navs>
        )}
        <Divider orientation={'vertical'} />
        <SortBy>
          <DropDownButton
            value={sortValue}
            showActiveCheck
            onChange={onSortChange}
            options={Object.keys(postSort)?.map((sortKey) => ({
              label: translate(`postListPage.sorting.${sortKey}`),
              value: sortKey,
            }))}
          >
            <>
              {isMobile ? (
                <SortIcon
                  lang={websiteLanguage}
                  width={`21px`}
                  height={`21px`}
                  src={IconAssets.mobileSortIcon}
                  alt={'mobile-sort'}
                />
              ) : (
                <span id={'sort-by_sort-icon'} className={'icon icon-sort'} />
              )}
              <SortText>{translate(`filters.sortBy`)}</SortText>
            </>
          </DropDownButton>
          {filter}
        </SortBy>
      </Root>

      <RootSmall>
        <TopContainer>
          <ResultCount>
            {translate(`searchResult`)(data?.numberOfResults || 0)}
          </ResultCount>
          <SortBy>
            <DropDownButton
              value={sortValue}
              showActiveCheck
              onChange={onSortChange}
              options={Object.keys(postSort).map((sortKey) => ({
                label: translate(`postListPage.sorting.${sortKey}`),
                value: sortKey,
              }))}
            >
              <>
                {isMobile ? (
                  <SortIcon
                    lang={websiteLanguage}
                    width={`21px`}
                    height={`21px`}
                    alt={`mobile sort`}
                    src={IconAssets.mobileSortIcon}
                    id={'sort-by_sort-icon'}
                  />
                ) : (
                  <span id={'sort-by_sort-icon'} className={'icon icon-sort'} />
                )}
                <SortText>{translate(`filters.sortBy`)}</SortText>
              </>
            </DropDownButton>
            {filter}
          </SortBy>
        </TopContainer>
        <SliderContainer>
          <ResultCountSmall>
            {translate(`searchResult`)(data?.numberOfResults || 0)}
          </ResultCountSmall>
          <Swiper spacing={8}>
            {getFilterList(data ? data.filters : [])?.map((filter, key) => (
              <SwiperItem key={key} reRender>
                <ToggleButton
                  key={key}
                  label={filter}
                  isActive={selectedOptions.includes(filter)}
                  onClick={() => handleSelect(filter)}
                />
              </SwiperItem>
            ))}
          </Swiper>
        </SliderContainer>
      </RootSmall>
    </>
  );
}

PostSortBar.propTypes = {
  onSortChange: PropTypes.func,
  sortValue: PropTypes.string,
  categoryId: PropTypes.string,
  keyword: PropTypes.string,
  selectedOptions: PropTypes.arrayOf(PropTypes.string),
  onOptionSelect: PropTypes.func,
  filter: PropTypes.node,
  totalCount: PropTypes.number,
};

export default PostSortBar;
