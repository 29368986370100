/* eslint-disable max-lines */
const de = {
  general: {
    ofText: 'von',
    outOf: 'Von',
    comma: ',',
    currency: 'EUR',
  },
  language: {
    name: 'English',
  },
  pagination: {
    pageText: 'Seite',
  },
  postActions: {
    done: 'Fertig!',
  },
  languagesDropdown: {
    selectLanguage: 'Sprache auswählen',
    submit: 'Bestätigen',
  },
  readMoreComponent: {
    showLess: 'weniger anzeigen',
    readMore: 'mehr lesen...',
  },
  postCardsSlider: {
    zeroState: 'Keine Produkte verfügbar',
    browseAll: 'Alle durchsuchen',
  },
  productConditions: {
    newText: 'Neu',
    mint: 'Wie neu',
    used: 'Gebraucht',
    defective: 'Defekt',
    defect: 'Mangel',
    newWithOpenBox: 'Neu mit geöffneter Verpackung',
    newAndSealed: 'Neu und versiegelt',
  },
  loginPage: {
    login: 'Einloggen',
    loginTitle: (width, screenSize) => {
      if (width <= screenSize) {
        return 'Willkommen zurück!';
      } else {
        return 'Sicher und bequem kaufen & verkaufen';
      }
    },
    username: 'Benutzername',
    usernamePlaceholder: 'Geben Sie Ihren Benutzernamen ein',
    password: 'Passwort',
    passwordPlaceholder: 'Geben Sie Ihr Passwort ein',
    forgetPassword: 'Passwort vergessen?',
    signIn: 'Anmelden',
    dontAccount: 'Noch keinen Account?',
    phone: 'Telefon',
    phonePlaceholder: 'Geben Sie Ihre Telefonnummer ein',
    loginByPhone: 'Mit Telefon anmelden',
    loginByUsername: 'Mit Benutzername anmelden',
    signUp: 'Registrieren',
  },
  userFeedback: {
    watchlist: {
      addedSuccessfully: 'Erfolgreich zur Watchlist hinzugefügt.',
      removedSuccessfully: 'Erfolgreich aus der Watchlist entfernt.',
    },
    httpErrorMsg: {
      connectionError: 'Sie sind nicht mit dem Internet verbunden',
      somethingWrong: 'Etwas ist schiefgelaufen',
    },
    categoriesPage: {
      addedSuccessfully: 'Erfolgreich zu den Favoriten hinzugefügt.',
      removedSuccessfully: 'Erfolgreich aus den Favoriten entfernt.',
    },
    loginPage: {
      loginSuccessful: 'Sie haben sich erfolgreich eingeloggt.',
      logoutSuccessfully: 'Erfolgreich abgemeldet.',
    },
    searchForm: {
      historyCleared: 'Verlauf erfolgreich gelöscht',
    },
    otpForm: {
      successSubmission:
        'Sie haben sich erfolgreich registriert! Jetzt die App herunterladen!',
    },
    registrationForm: {
      recaptchaError: 'Fehler bei der Überprüfung von reCAPTCHA.',
    },
  },
  watchlistPage: {
    dayCount: (count) => {
      const number = count.toNumberFormat();
      return count > 1 ? `${number} Tage` : `${number} Tag`;
    },
    favouriteSP: 'Bevorzugter Servicepunkt',
    locationOnMaps: 'Standort auf der Karte',
    workingHours: 'Öffnungszeiten',
    viewOnMap: 'Auf Google Maps anzeigen',
    holiday: 'Feiertag',
    watchlist: 'Watchlist',
  },
  forgetPasswordForm: {
    title: 'Passwort zurücksetzen',
    info: 'Geben Sie die mit Ihrem Konto verbundene Telefonnummer ein.',
    reset: 'Zurücksetzen',
  },
  resetPasswordForm: {
    title: 'Neues Passwort festlegen',
    info: 'Wählen Sie ein starkes Passwort mit Buchstaben und Zahlen',
    newPassword: 'Neues Passwort',
    newPasswordPlaceholder: 'Passwort muss mindestens 8 Zeichen haben',
    confirmPassword: 'Passwort bestätigen',
    confirmPasswordPlaceholder: 'Neues Passwort erneut eingeben',
    resetPassword: 'Passwort zurücksetzen',
  },
  accountDropdown: {
    profile: 'Profil',
    watchlist: 'Watchlist',
    notification: 'Benachrichtigungen',
    logout: 'Abmelden',
    login: 'Anmelden',
    shoppingCart: 'Einkaufswagen',
  },
  popUpsActions: {
    proceed: 'Fortfahren',
    cancel: 'Abbrechen',
  },
  legalPages: {
    top: 'OBEN',
    topics: 'Themen',
    productPolicy: 'Produktpolitik',
    businessRegistration: 'Registrierungsrichtlinie für Geschäftsnutzer',
    userAgreement: 'Nutzungsvereinbarung',
    itemCondition: 'Leitfaden für den Zustand von Artikeln',
    feesPolicy: 'Gebührenrichtlinie',
    privacyPolicyTopics: {
      introduction: 'Einführung und Einwilligung',
      topics: 'Unter diese Datenschutzrichtlinie fallende Themen',
      collection: 'Unsere Sammlung Ihrer persönlichen Daten',
      purposes:
        'Zwecke, für die Smarkt Ihre personenbezogenen Daten erhebt, speichert, verwendet und verarbeitet',
      update: 'Aktualisierung der Datenschutzrichtlinie',
      personalInfo:
        'Zugriff auf, Überprüfung und Änderung Ihrer persönlichen Daten',
      entities: 'Einheiten, mit denen wir Ihre persönlichen Daten teilen',
      marketing: 'Marketing und Kommunikation',
      cookies: 'Cookies',
      protect: 'Schutz Ihrer persönlichen Daten',
      general: 'Allgemeine Bestimmungen',
    },
    termsAndConditionsTopics: {
      introduction: 'Einführung und Annahme',
      service: 'Der Service',
      fees: 'Gebühren',
      wallet: 'Meine Smarkt-Wallet',
      promotions: 'Bezahlte Werbedienstleistungen',
      conditions:
        'Einstellungs-/Verwendungsbedingungen für die Smarkt-Plattform',
      obligations: 'Verkäuferpflichten',
      buyandbid: 'Kaufen und Bieten',
      collectionpoints: 'Abholstationen (Click & Collect)',
      userRepresentations: 'Nutzerdarstellungen und Garantien',
      registration: 'Registrierung und Kontoerstellung',
      userViolations: 'Nutzerverstöße und Sperrung oder Löschung von Konten',
      eligibility:
        'Berechtigung zur Mitgliedschaft und Fähigkeit, die Nutzungsvereinbarung zu schließen',
      amendments: 'Änderungen dieser Nutzungsvereinbarung',
      governingLaw: 'Anwendbares Recht',
      disputeResolutionForum: 'Streitbeilegungsforum',
      limitation: 'Haftungsbeschränkung',
      warrantiesDisclaimer: 'Haftungsausschluss für Garantien',
      customerSupport: 'Smarkt-Kundensupport und Nutzerfeedback-Richtlinien',
      indemnity: 'Freistellung',
      daydeal: 'Deal des Tages',
      licenses: 'Lizenzen',
      intellectualPropertyRights:
        'Geistige Eigentumsrechte, Patente und Marken',
      generalProvisions: 'Allgemeine Bestimmungen',
    },
    productPolicyTopics: {
      introduction: 'Einführung und Einwilligung',
      prohibitedProducts: 'Verbotene Produkte',
      restrictedProducts: 'Eingeschränkte Produkte',
      violation:
        'Verstoß gegen diese Politik für verbotene und eingeschränkte Produkte',
    },
    businessRegistrationTopics: {
      registrationPolicy: 'Registrierungsrichtlinie für Geschäftsnutzer',
      businessEntity: 'Registrierung einer Geschäftseinheit',
    },
    conditionGuideTopics: {
      conditionGuide: 'Leitfaden für den Zustand von Artikeln',
      categoriesAndDefinitions: 'Verfügbare Kategorien und Definitionen',
    },
    feesPolicyTopics: {
      feesPolicy: 'Gebührenrichtlinie',
      transactionFees: 'Transaktionsgebühren',
      promotionsFees: 'Gebühren für bezahlte Werbung',
      storageFees: 'Lagergebühren',
    },
  },
  filters: {
    sortBy: 'Sortieren nach',
    search: 'Suche',
    filter: 'Filtern',
    result: 'Ergebnis für',
    filterSearch: 'Im Filter suchen',
    resetAllFilter: 'Alle Filter zurücksetzen',
    reset: 'Zurücksetzen',
    apply: 'Anwenden',
    fromText: 'Von',
    to: 'Bis',
  },
  postListPage: {
    price: 'Preis',
    category: 'Kategorie',
    pricing: 'Preisgestaltung',
    zeroState: 'Keine Ergebnisse anzuzeigen',
    noFilterResult: 'Keine Ergebnisse mit Ihren Filteroptionen',
    clearAll: 'Alles löschen',
    sellingMethod: 'Verkaufsmethode',
    sliderSectionTitle: 'Ausgewählte Beiträge',
    filterResult: (count) => {
      if (count === 1) return `1 Beitrag gefunden`;
      if (count > 100) return `100+ Beiträge gefunden`;
      return `${count.toNumberFormat()} Beiträge gefunden`;
    },
    filterChipsSeeMore: 'Mehr anzeigen',
    filterChipsSeeLess: 'Weniger anzeigen',
    sorting: {
      lowToHigh: `Preis: Niedrig bis Hoch`,
      highToLow: `Preis: Hoch bis Niedrig`,
      newlyAdded: `Neu hinzugefügt`,
      sellerRating: `Verkäuferbewertung`,
      endingSoonest: `Endet bald`,
    },
    keys: {
      category: 'Kategorie',
      price: 'Preis',
      sellingMethod: 'Verkaufsmethode',
      condition: 'Zustand',
      secured: 'Gesichert von Smarkt',
    },
  },
  searchPage: {
    zeroState: `Kein Ergebnis mit Ihrer Suche`,
    resultFor: (keyword) => `Suchergebnis für "${keyword}"`,
  },
  searchResult: (count) => {
    if (count === 1) return `1 Ergebnis`;
    if (count > 100) return `100+ Ergebnisse`;
    return `${count.toNumberFormat()} Ergebnisse`;
  },
  httpErrorMsg: {
    somethingWrong: 'Etwas ist schiefgelaufen',
    notFound: 'Nicht gefunden',
    connectionError: 'Sie sind nicht mit dem Internet verbunden',
    unauthorized: 'Nicht autorisiert',
    noRefreshToken: 'Kein Aktualisierungstoken',
    pleaseTryAgain:
      'Interner Serverfehler, bitte versuchen Sie es später erneut',
  },
  notFoundPage: {
    title: 'Ooops, diese Seite wurde nicht gefunden',
    description:
      'Es tut uns sehr leid, aber die von Ihnen angeforderte Seite fehlt. Vielleicht können wir Ihnen helfen oder Sie gehen zurück zur Startseite',
    backToHome: 'Zurück zur Startseite',
    contactUs: 'Kontaktieren Sie uns',
  },
  reportAbuseComponent: {
    reportAbuse: 'Missbrauch melden',
  },
  createPostButton: {
    createAuctionTitle: 'Auktion erstellen',
    createDirectSaleTitle: 'Direktverkauf erstellen',
    createAuctionSubtitle: 'Höchster Marktpreis',
    createDirectSaleSubtitle: 'Ihr eigener Sofortkaufpreis',
    mazadatValetText: 'Smarkt Valet',
    mazadatValetLabel: 'Neu',
  },
  smallPostTimerComponent: {
    ends: 'Endet',
    inText: 'in',
    expired: 'Abgelaufen',
    timer3MinsRuleText: 'Letzte Chance',
  },
  postDetailsPage: {
    productDescription: 'Produktbeschreibung',
    questionsAndAnswers: 'Fragen & Antworten',
    loadMore: 'Mehr laden',
    aboutSeller: 'Über den Verkäufer',
    securedBy: 'Gesichert durch',
    dontMissOut: 'Verpassen Sie das nicht!',
    willExpireIn: 'Wird in ablaufen',
    days: 'Tage',
    hours: 'Stunden',
    minutes: 'Minuten',
    seconds: 'Sekunden',
    material: 'Material',
    buyNow: 'Jetzt kaufen',
    bidNow: 'Jetzt bieten',
    buyFor: 'Kaufen für',
    youMadePurchase: 'Sie haben diesen Artikel am gekauft',
    itemsAreRemaining: 'verbleibende Artikel',
    yourBidIsLeading: 'Ihr Gebot führt!',
    noDescription: 'Noch keine Beschreibung vorhanden!',
    noQuestionsAndAnswers: 'Noch keine Fragen und Antworten',
    zeroState: 'Noch keine Bewertungen!',
    loadMoreQuestions: 'Mehr Fragen laden',
    itemsSoon: 'Artikel bald',
    soldOut: 'ausverkauft!',
    itemSoldOut: 'Dieser Beitrag ist ausverkauft',
    thisPostNotAvailable: 'Dieser Beitrag ist nicht verfügbar',
    notSecured: 'Verkäufer-Käufer direkt',
    securedItemMobileView: 'Dieser Artikel ist gesichert durch Smarkt',
    zoomImage: 'Über das Bild fahren, um zu zoomen',
    pickupIs: 'Abholung ist',
    deliverBy: 'Lieferung bis',
    pickupNotAvailable: 'nicht verfügbar',
    pickupAvailable: 'verfügbar',
    mazadat: 'Smarkt',
    reply: 'Antworten',
    maxQtyExceededText: 'Maximale Menge überschritten!',
    differentCountryState: {
      title: 'Ooops, dieser Beitrag ist in Ihrem Land nicht verfügbar.',
      description:
        'Es tut uns leid, aber der Beitrag, den Sie gerade geöffnet haben, ist in Ihrem Land nicht verfügbar. Vielleicht können wir Ihnen helfen oder Sie gehen zurück zur Startseite',
    },
    brandInfoComponent: {
      category: 'Kategorie',
      subCategory: 'Unterkategorie',
      warranty: 'Garantie',
      endTime: 'Endzeit',
    },
    actions: {
      postSimilarButton: 'Ähnlichen Beitrag erstellen',
      shareButton: 'Teilen',
    },
    bid: (number) => {
      if (number === 1) {
        return `1 Gebot`;
      } else {
        return `${number.toNumberFormat()} Gebote`;
      }
    },
    viewAnswers: 'Antworten anzeigen',
    reviewZeroState: 'Noch keine Bewertungen!',
    viewReviews: 'Bewertungen anzeigen',
    tabToExpand: 'Zum Vergrößern tippen',
    addReview: 'Bewertung hinzufügen',
    comments: 'Kommentare',
    totalComments: 'Gesamtzahl der Kommentare',
    viewReplies: (number) => {
      if (number === 1)
        return `Zeige ${number.toNumberFormat()} vorherige Antwort...`;
      else return `Zeige ${number.toNumberFormat()} vorherige Antworten...`;
    },
    hideReplies: 'Antworten ausblenden',
    whatAboutThose: 'Wie wäre es mit diesen?',
    cantFetchSection: 'Kann Abschnittsdaten nicht anzeigen',
    backToHome: 'Zurück zur Startseite',
    reportAProblem: 'Ein Problem melden',
    cancel: 'Abbrechen',
    viewAllItems: 'Alle Artikel anzeigen',
  },
  widgets: {
    securedItem: 'Dieser Artikel ist gesichert durch Smarkt',
    securedItemTooltip:
      'Greifen Sie auf die Servicepunkte von Smarkt zu, um Ihre Bestellungen sicher abzuschließen',
    notSecured: 'Verkäufer-Käufer direkt',
    secure: '100% sicher',
    payments: 'Vielfältige Zahlungsmethoden',
    delivery: 'Produktlieferung',
    auctionOrDirectSale: 'Auktion oder Direktverkauf',
    servicePointsNetwork: 'Erweiterbares Netzwerk von Servicepunkten',
    winWinComissions: 'Win-Win-Provisionen',
    digitalInvoice: 'Digitale Rechnung',
    activeItems: 'Aktive Beiträge von',
    viewAllItems: 'Alle Artikel anzeigen',
    secureDes:
      'Mit Smarkt sind Sie vor Überfällen, Belästigungen oder Bedrohungen sicher.',
    deliveryDes:
      'Mit Smarkt können Sie Ihre Bestellung jederzeit und überall liefern lassen.',
    paymentsDes:
      'Sie können ganz einfach mit der digitalen Wallet von Smarkt, Kreditkarten, Ratenzahlung oder Barzahlung bezahlen.',
    auctionOrDirectSaleDes:
      'Auf Smarkt können Sie Ihre Artikel über eine Auktion, einen Direktverkauf oder eine Mischung aus beidem verkaufen.',
    servicePointsNetworkDes:
      'An den Servicepunkten von Smarkt können Verkäufer und Käufer die Produkte vor dem Abschluss des Kaufs inspizieren, ohne sich Gedanken über das Treffen oder Vertrauen fremder Personen zu machen.',
    winWinComissionsDes:
      'Sie zahlen unsere Provision nur, wenn Sie Ihre Produkte verkaufen. Erstellen Sie so viele kostenlose Beiträge, wie Sie möchten, und sobald Sie verkaufen, werden Sie bezahlt.',
    digitalInvoiceDes:
      'Mit Smarkt können Sie Ihre digitalen Rechnungen für Ihre verkauften Artikel sicher und bequem erstellen.',
    learnMore: 'Mehr erfahren',
  },
  biddingHistorySidebar: {
    viewHistory: 'Verlauf anzeigen',
    biddingHistory: 'Gebotsverlauf',
    biddingPrice: 'Gebotspreis:',
    bids: 'Gebote',
    bidders: 'Bieter',
    highestBid: 'Höchstes Gebot',
    noBidsAvailable: 'Keine Gebote verfügbar',
    beTheFirstToBid: 'Noch keine Gebote! Seien Sie der Erste.',
    lastBid: 'Letztes Gebot',
    nextBid: 'Nächstes Gebot',
    bidNow: 'Jetzt bieten',
  },
  header: {
    shopByCategory: 'Nach Kategorie einkaufen',
    createPost: 'Beitrag erstellen',
    becomeBusiness: 'Geschäft werden?',
  },
  footer: {
    safePayments: 'Sie können bezahlen via',
    allRights: 'Alle Rechte vorbehalten.',
    mazadatInc: '©Smarkt SAE.',
    contactInfo: 'Kontaktinformationen',
    customerService: 'Kundendienst',
    termsConditions: 'Geschäftsbedingungen',
    contactUs: 'Kontaktieren Sie uns',
    privacyPolicy: 'Datenschutzrichtlinie',
    downloadApp: 'Laden Sie unsere App herunter',
    mazadat: 'Smarkt',
    aboutUs: 'Über uns',
    howITWorks: 'Wie es funktioniert',
    becomeBusiness: 'Geschäft werden?',
  },
  footerMobile: {
    downloadApp: 'Laden Sie unsere App herunter',
  },
  sharePopup: {
    shareThisPost: 'Diesen Beitrag teilen',
    copyLink: 'Oder Link kopieren',
    copiedToClipboard: 'In die Zwischenablage kopiert',
    copy: 'Kopieren',
  },
  aboutSellerView: {
    verifiedSeller: 'Verifizierter Verkäufer',
    viewProfile: 'Verkäuferprofil anzeigen',
    totalRatingAsSeller: 'Gesamtbewertung als Verkäufer',
    showReviews: 'Bewertungen anzeigen',
    hideReviews: 'Bewertungen ausblenden',
    reviewsIn: 'Bewertungen in den letzten',
    soldItems: (number) => {
      if (number < 1) return `Mitglied seit`;
      else if (number > 1) return `Artikel seit`;
      else return 'Artikel seit';
    },
    sold: 'Verkauft',
    outOf: 'von',
    chatWithSeller: 'Mit dem Verkäufer chatten',
    loadMoreReview: 'Mehr Bewertungen laden',
    addReview: 'Bewertung hinzufügen',
    zeroState: 'Noch keine Bewertungen!',
  },
  ratingView: {
    commitment: 'Engagement',
    communication: 'Kommunikation',
    speedAndDelivery: 'Geschwindigkeit und Lieferung',
    overallExperience: 'Gesamterfahrung',
    totalRatingText: 'Gesamtbewertung',
    viewDetails: 'Details anzeigen',
  },
  pagerButtonComponent: {
    next: 'Weiter',
    previous: 'Zurück',
  },
  postCard: {
    qtyText: (quantity, bids, discount) => {
      const number = quantity.toNumberFormat();
      if (discount === 'amount') return '';
      if (bids > 0 || !!discount) return `${number} Übrig`;
      if (quantity === 1) return `Nur 1 Artikel verfügbar`;
      if (quantity > 1) return `${number} Artikel verfügbar`;
      if (quantity === 0) return 'Keine Artikel verfügbar';
      return '';
    },
    bidsNumberText: (bids, discount) => {
      if (discount === 'amount') return '';
      if (bids === 1) return `1 Gebot`;
      if (bids > 1) return `${bids.toNumberFormat()} Gebote`;
      return ``;
    },
    discountValuePrefix: 'Sparen',
    off: 'RABATT',
    bidNow: 'Jetzt bieten',
    buyNow: 'Jetzt kaufen',
    soldOut: 'Ausverkauft!',
    notAvailableViewComponent: {
      notAvailable: 'Nicht verfügbar',
    },
  },
  homePage: {
    title: 'Bleiben Sie dran! Die Startseite wird bald gestartet!',
    description:
      'Sie können nach Artikeln suchen oder das Menü verwenden, um Smarkt zu durchsuchen',
    featuredPostsTitle: 'Ausgewählte Beiträge',
    topCategoriesTitle: 'Top-Kategorien',
    startingFromValue: '100 EUR',
    dayDeal: {
      title: 'Tagesangebot',
      dontMissOutWillExpiresIn:
        'Verpassen Sie das nicht! Der Beitrag läuft in ab',
      discoverMore: 'Mehr entdecken',
      qtyText: (value) => {
        if (value === 1) return `Nur 1 Artikel übrig`;
        if (value > 1) return `${value.toNumberFormat()} Artikel übrig`;
        if (value === 0) return 'Keine Artikel übrig';
        return '';
      },
    },
    comingSoon: {
      title: 'Bleiben Sie dran für großartige Sparangebote!',
      weWillNotifyIn: 'Wir benachrichtigen Sie in',
      downloadAppGetLatest:
        'Laden Sie die App herunter und erhalten Sie die neuesten Angebote!',
    },
  },
  cookies: {
    siteCookies: 'Hey! Diese Website verwendet Cookies.',
    cookiesDescription:
      'Wir verwenden Cookies, um Ihr Erlebnis auf dieser Website zu verbessern.',
    acceptCookies: `Okie Cookie!`,
  },
  megaMenu: {
    close: 'Schließen',
  },
  downloadPopup: {
    content: 'Laden Sie unsere App herunter, um diese Funktion zu nutzen.',
    close: 'Schließen',
    downloadAppNow: 'Smarkt jetzt herunterladen!',
  },
  sendMessageForm: {
    title: 'Kontaktieren Sie uns!',
    description: `Wir glauben, dass gute Gespräche großartige Ideen auslösen!`,
    fullName: 'Vor-, Mittel- und Nachname',
    nameErr: 'Name',
    subjErr: 'Betreff',
    mobileErr: 'Handynummer',
    emailAddress: 'Geben Sie Ihre E-Mail-Adresse ein',
    emailAddressErr: 'E-Mail-Adresse',
    mobileNumber: 'Geben Sie Ihre Handynummer ein',
    subject: 'Geben Sie Ihren Betreff ein',
    message: 'Bitte geben Sie Ihre Nachricht ein',
    submitMessage: 'Nachricht senden',
    closeNow: 'Schließen',
    thankYou: 'Vielen Dank!',
    characters: 'Zeichen',
    successMessageDescription:
      'Ihre Nachricht wurde erfolgreich gesendet. Unser Team wird sich so schnell wie möglich bei Ihnen melden.',
  },
  contactUsPage: {
    weAreHere: {
      title: `Kontaktieren Sie uns!`,
      description: `Teilen Sie uns Ihre Gedanken mit!`,
      headOffice: 'Hauptsitz',
      nasrCity: 'Nasr City',
      nasrCityAddress: '17 Mostafa EL-Nahaas Nasr City, Kairo',
      phoneNumber: 'Telefonnummer',
      mobileNumber: 'Handynummer',
    },
    letUsTalk: {
      title: `Brauchen Sie weitere Hilfe?`,
      shareExcitement: 'Teilen Sie uns Ihre Erfahrungen mit!',
      sendInquiry: 'Oder senden Sie uns Ihre Anfrage',
    },
    ourServicePoints: {
      title: 'Unsere Servicepunkte',
      description: 'Wir sind immer für Sie da!',
      viewMap: 'Karte anzeigen',
      locationOnMaps: 'Standort auf der Karte',
      workingHours: 'Öffnungszeiten',
      viewOnMap: 'Auf Google Maps anzeigen',
      holiday: 'Feiertag',
    },
    contactFromHeart: {
      title: 'Bleiben Sie in Kontakt!',
      description: `Folgen Sie uns!`,
    },
  },
  getHelpComponent: {
    title: `Brauchen Sie weitere Hilfe?`,
    shareExcitement: 'Teilen Sie uns Ihre Erfahrungen mit!',
    sendInquiry: 'Oder senden Sie uns Ihre Anfrage',
  },
  textFieldComponent: {
    characters: 'Zeichen',
    optionalLabel: 'Optional',
    password: 'Passwort',
  },
  phoneNumberComponent: {
    phoneNumber: 'Handynummer',
    code: 'Code',
    selectCode: 'Code auswählen',
  },
  registrationForm: {
    joinUs: 'Melden Sie sich bei uns an',
    byContinuing: (width, screenSize) => {
      if (width <= screenSize) {
        return 'Indem Sie fortfahren, stimmen Sie den Smarkt-Bedingungen zu';
      } else {
        return 'Indem Sie fortfahren, stimmen Sie unseren';
      }
    },
    termsOfService: (width, screenSize) => {
      if (width <= screenSize) {
        return 'Nutzungsbedingungen';
      } else {
        return 'Geschäftsbedingungen';
      }
    },
    registeredBusiness: 'Registriertes Unternehmen',
    invitationCode: 'Einladungscode',
    verify: 'Bestätigen',
    phoneNumber: 'Handynummer',
    code: 'Code',
    username: 'Benutzername',
    enterUsername: 'Geben Sie Ihren Benutzernamen ein',
    password: 'Passwort',
    passwordCharacters: 'Geben Sie Ihr Passwort ein',
    title: (width, screenSize) => {
      if (width <= screenSize) {
        return 'Lassen Sie uns loslegen';
      } else {
        return 'Wenn sich die Gelegenheit nicht bietet, bauen Sie eine Tür.';
      }
    },
    successSubmission:
      'Sie haben sich erfolgreich registriert! Jetzt die App herunterladen!',
    businessTooltip:
      'Registrieren Sie sich als Geschäftsnutzer und profitieren Sie von Smarkts einzigartigem E-Commerce-Erlebnis, um Ihr Geschäft zu erweitern.',
    invitationCodeTooltip:
      'Fügen Sie hier Ihren Einladungscode ein, wenn Sie von einem Nutzer eingeladen wurden.',
    userNameCheckList: {
      usernameCharLength: '3 bis 30 Zeichen (Buchstaben oder Zahlen).',
      noSpecialChar: 'Keine Sonderzeichen am Anfang oder Ende.',
      noSpaces: 'Keine Leerzeichen.',
      noUpperCase: 'Keine (GROSSBUCHSTABEN) Großbuchstaben.',
      lessThanSix: 'Maximal 5 Zahlen.',
    },
    invitationCodePlaceHolder: 'Einladungscode eingeben',
    signUp: 'Registrieren',
    signIn: 'Anmelden',
    charAnd: '&',
    codeError: 'Bitte Code auswählen',
    selectCode: 'Code auswählen',
    alreadyAccount: 'Schon ein Konto?',
    privacyPolicy: 'Datenschutzrichtlinie',
  },
  validationErrorMsg: {
    required: (name) => `${name} ist erforderlich`,
    min: (name, min) => `${name} muss mindestens ${min} Zeichen haben`,
    max: (name, max) => `${name} darf höchstens ${max} Zeichen haben`,
    invalid: (name) => `Ungültiges ${name}!`,
    nameError: 'Benutzername ist ungültig, bitte überprüfen Sie die Regeln',
    confirmPassword: 'Passwörter stimmen nicht überein',
  },
  otpForm: {
    title: 'Verifizieren Sie Ihre Handynummer.',
    enterTheCode: 'Bitte geben Sie den Zahlencode ein',
    changeNumber: 'Nummer ändern',
    notReceiveCode: 'Code nicht erhalten?',
    resendIn: 'Erneut senden in',
    resendButton: 'Erneut senden',
    verify: 'Verifizieren',
    enterTheCodeSentToEmail: 'Geben Sie den an Ihre E-Mail gesendeten Code ein',
    changeEmail: 'E-Mail ändern',
    otpInfo:
      'Geben Sie den 4-stelligen Code ein, der an Ihr Handy gesendet wurde:',
    whatsapp: 'Oder via WhatsApp',
    successSubmission:
      'Sie haben sich erfolgreich registriert! Jetzt die App herunterladen und die Funktionen von Smarkt entdecken.',
    whatsappCodeSentSucess: 'Code erfolgreich an WhatsApp gesendet',
  },
  passwordStrength: {
    weakPassword: 'Schwaches Passwort!',
    averagePassword: 'Durchschnittliches Passwort!',
    greatPassword: 'Starkes Passwort!',
  },
  breadCrumb: {
    home: 'Startseite',
    editPost: 'Beitrag bearbeiten',
    similarPost: 'Ähnlicher Beitrag',
    sharePost: 'Beitrag teilen',
    addToWatchList: 'Zur Watchlist hinzufügen',
    reportAbuse: 'Ein Problem melden',
    privacyPolicy: 'Datenschutzrichtlinie',
    termsAndConditions: 'Geschäftsbedingungen',
    productPolicy: 'Produktpolitik',
    businessRegistrationPolicy: 'Registrierungsrichtlinie für Geschäftsnutzer',
    feesPolicy: 'Gebührenrichtlinie',
    aboutUs: 'Über uns',
    howItWorks: 'Wie es funktioniert',
    categories: 'Kategorien',
    sellerProfile: 'Verkäuferprofil',
    comingSoon: 'Demnächst verfügbar',
    notFound: 'Nicht gefunden',
    contactUs: 'Kontaktieren Sie uns',
  },
  bottomMenu: {
    profile: 'Profil',
    search: 'Suche',
    categories: 'Kategorien',
    home: 'Startseite',
  },
  searchView: {
    searchFieldPlaceHolder: `Ich suche nach...`,
  },
  aboutUsPage: {
    shoppingExperience: {
      title:
        'Bei Smarkt schaffen wir Wege für Käufer und Verkäufer zu einem einzigartigen Einkaufserlebnis',
      description: `Wir sind die erste Auktions- und X2X-Marktplattform im Nahen Osten und Afrika und bieten unseren Nutzern ein einzigartiges Einkaufserlebnis, bei dem Privatpersonen (C2C) und Unternehmen unterschiedlicher Größe (B2C) ihre Artikel und Produkte verkaufen können. Benutzer können Artikel in zeitlich begrenzten Auktionen, Festpreis-Listings oder einem Hybridformat verkaufen, während Käufer die kombinierte Vielfalt der Angebote an einem Ort genießen.`,
      scroll: 'scrollen',
    },
    innerSections: {
      onlineAndOffline: 'Brücke zwischen der Online- und Offline-Welt',
      onlineAndOfflineDesc: `Mit der Brücke zwischen der Online- und Offline-Welt hat Smarkt das dezentrale Netzwerk von physischen Servicepunkten erfunden, das das Sinnbild eines „vertrauenslosen“ Modells ist, bei dem alle Parteien; Einzelpersonen, Geschäftsinhaber, Käufer und Verkäufer müssen dem Fremden/dem zweiten Teil nicht vertrauen. Dieses einzigartige Modell unterstützt Händler und Anbieter, einschließlich kleiner und Kleinstunternehmen, mit mehreren Vorteilen wie Lagerfläche, Logistik und schnellen Serviceanforderungen und eröffnet ihnen eine endlose Chance, ihr Geschäft auszubauen und ihre Marken weiter zu fördern.`,
      innovation: `Innovative und nachhaltige X2C-Kanäle aufbauen`,
      innovationDesc: `Bei Smarkt streben wir danach, eine Gemeinschaft für alle durch skalierbare E-Commerce-Erlebnisse aufzubauen, in der jeder wachsen und gedeihen kann. Wir sind fest davon überzeugt, dass kleine Bemühungen einen großen Unterschied machen können. Daher sind wir seit der frühen Gründung von Smarkt fest verwurzelt, um die Idee des Re-Commerce zu fördern (den Kauf und Verkauf gebrauchter Produkte), um Käufer und Verkäufer zu ermutigen, die Lebensdauer von Produkten zu verlängern und so zu einem gesünderen Planeten und einer grünen Wirtschaft beizutragen.`,
      safety: `Bequemlichkeit und Sicherheit für Nutzer bieten`,
      safetyDesc: `Wir bemühen uns, das Leben der Menschen durch die Erschwinglichkeit der Produktservices von Smarkt zu verbessern, gepaart mit der Bequemlichkeit und Sicherheit, die durch das Konzept seiner einzigartigen Servicepunkte im ganzen Land geboten wird, was eine Win-Win-Situation für Unternehmen jeder Größe darstellt; da sie von einer Vielzahl an Mehrwertdiensten für Anbieter in den Bereichen Logistik, Lagerung und Auftragsabwicklung profitieren können und die Käufer ihre Einkäufe sicher und anonym überprüfen können.`,
      safetyDescSub: `Durch das Angebot einer Vielzahl von digitalisierten Zahlungsmöglichkeiten verspricht Smarkt seinen Kunden zugängliche, bequeme Käufe über seine Plattform und bequeme Ratenzahlungsoptionen.`,
    },
    mazadatValues: {
      sectionTitle: `Smarkt-Werte`,
      firstSlide: {
        title: 'Innovation',
        desc: 'Unser erfundenes Geschäftsmodell bietet unseren Nutzern ein einzigartiges Einkaufserlebnis. Unser Anwendungssystem verwendet die neueste Technologie, um ein reibungsloses Einkaufserlebnis zu kuratieren, das nur einen Klick entfernt ist.',
      },
      secondSlide: {
        title: 'Sicherheit',
        desc: 'Unsere Marktplattform dreht sich alles um Sicherheit und den Aufbau von Vertrauen in einer vertrauenslosen Welt. Wir bieten einen Treuhandservice an, der sowohl für den Käufer als auch für den Verkäufer Zufriedenheit gewährleistet.',
      },
      thirdSlide: {
        title: 'Inklusivität',
        desc: 'Jeder Nutzer kann auf unsere Plattform zugreifen, unabhängig von Sprache, Standort, finanzieller Lage und Lebensstil. Die Inklusivität und Zugänglichkeit von Smarkt zeigt sich am besten in seiner mehrsprachigen Struktur, den vielfältigen Zahlungsoptionen und den flexiblen Versandoptionen, die unterschiedlichen Bedürfnissen gerecht werden.',
      },
      fourthSlide: {
        title: 'Nachhaltigkeit',
        desc: 'Wir fördern die Idee des Re-Commerce; Käufer und Verkäufer ermutigen, die Lebensdauer von geliebten Artikeln zu verlängern. Dies trägt zur Abfallreduzierung bei und schont Ressourcen.',
      },
      fifthSlide: {
        title: 'Zusammenarbeit',
        desc: 'Wir streben danach, eine Gemeinschaft für alle durch ein skalierbares E-Commerce-Erlebnis aufzubauen. Egal ob Sie Student, Mutter oder Anbieter sind, der seine Reichweite erweitern möchte, wir laden Sie ein, unserer Gemeinschaft beizutreten und mit uns zusammenzuarbeiten.',
      },
    },
    ourServicesPoint: {
      title: `Unsere Servicepunkte`,
      servicePoints: {
        heliopolis: 'Heliopolis',
        el_Mohandseen: `El Mohandseen`,
        nasrCity: 'Nasr City',
        maadi: 'Maadi',
      },
    },
    subscription: {
      title: `Wir würden gerne mehr über Ihr Geschäft erfahren`,
    },
  },
  howItWorksPage: {
    header: {
      smartAndSafe: 'smart. sicher.',
      convenient: 'bequem',
      bidBuyAndSell: 'bieten, kaufen & verkaufen',
      description:
        'Mit einem Konto bei Smarkt können Sie bieten, kaufen und verkaufen',
    },
    fiveProducts: {
      title: '5 verschiedene Produktzustände',
      newText: 'Neu',
      mint: 'Wie neu',
      used: 'Gebraucht',
      defective: 'Defekt',
      openBox: 'Geöffnete Verpackung',
    },
    checkOutMazadat: {
      title: `Sehen Sie sich die Listings von Smarkt in über 35 Kategorien an`,
    },
    forgetAboutScam: {
      title: 'Vergessen Sie Betrügereien! Gehen Sie gesichert durch Smarkt',
      description:
        'Ein erweiterbares Netzwerk von Servicepunkten, an denen Sie sicher, bequem und privat kaufen und verkaufen können!',
    },
    payment: {
      title: `Vielfältige und sichere Zahlungsoptionen`,
    },
    mazadatInNumbers: {
      title: `Smarkt in Zahlen`,
      circles: {
        download: 'Sekunden zum Herunterladen',
        auctions:
          'Sekunden, um eine Auktion oder einen Direktverkaufspost zu starten',
        dropOff:
          'Stunden, die für die Abgabe oder Abholung Ihres Artikels erlaubt sind',
        returnRequest: 'Stunden für einen Rückgabeantrag',
        sellerBuyer: 'Schutz von Käufer und Verkäufer',
        conditions: 'Verschiedene Produktzustände',
        increasePosts:
          'Werbepläne zur Auswahl, um den Verkauf Ihres Beitrags zu steigern',
      },
    },
    whySecured: {
      cards: {
        pickup: 'Abgabe & Abholung am Servicepunkt',
        inspect: 'Überprüfen, bevor Sie einen Artikel abholen',
        deliveryService: 'Liefer- und Lagerdienste sind verfügbar.',
        returnRequest: '48-Stunden-Fenster, um einen Rückgabeantrag zu stellen',
      },
      title: 'Warum "Gesichert durch Smarkt"',
    },
    shopSmart: {
      title: 'Jeden Tag smart einkaufen',
    },
  },
  categoriesPage: {
    favouriteCategories: 'Bevorzugte Kategorien',
    allCategories: 'Alle Kategorien',
    searchPlaceholder: 'In Kategorien suchen',
    noFavouriteSelected:
      'Klicken Sie auf das Herzsymbol, um zu Ihren Favoriten hinzuzufügen',
  },
  sellerProfilePage: {
    showContact: 'Kontakt anzeigen',
    shareProfile: 'Profil teilen',
    reviews: 'Bewertungen',
    activePost: 'Aktive Beiträge',
    sellerReviews: 'Verkäuferbewertungen',
    outOf: 'von',
    reportAbuse: 'Ein Problem melden',
    viewReviews: 'Bewertungen anzeigen',
    zeroState: 'Keine Bewertungen',
    noActivePosts: 'Keine aktiven Beiträge zu zeigen',
    newToOld: 'Neueste zuerst',
    oldToNew: 'Älteste zuerst',
    lowToHigh: 'Niedrigste Bewertung zuerst',
    highToLow: 'Höchste Bewertung zuerst',
  },
  underConstructionPage: {
    title: `Oh! Entschuldigung, diese Seite befindet sich im Aufbau.`,
    moreText:
      'In der Zwischenzeit können Sie unsere App herunterladen, um alle Funktionen von Smarkt zu nutzen',
  },
  downloadAppFooter: {
    title: 'Treten Sie jetzt bei und erkunden Sie die Welt von Smarkt',
    description: 'Laden Sie unsere App hier herunter',
  },
  serviceWorker: {
    installedMessage: 'Die App wurde installiert',
    updateMessage: 'Es gibt ein neues Update, bitte aktualisieren',
  },
  countrySelect: {
    title: 'Länderauswahl',
    apply: 'Anwenden',
  },
};

export default de;
