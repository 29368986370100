import i18n from './i18n';

/**
 * Helper function to translate i18n messages
 * @param {string} key Word to be translated
 * @returns {string} translated word
 */

export default function translate(key) {
  return i18n.t(key);
}
