import { I18nextProvider } from 'react-i18next';
import PropTypes from 'prop-types';
import i18n from './i18n';

const LocaleProvider = ({ children }) => {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
};

LocaleProvider.propTypes = {
  children: PropTypes.node,
};

export default LocaleProvider;
