import { createSlice } from '@reduxjs/toolkit';
import Service from '../../services';
import { LANGUAGES } from '../../constants/general';

const lang = Service.storageService.get('i18nextLng');

const initialState = {
  searchHistory: [],
  isDataLoading: false,
  error: '',
  websiteLanguage: LANGUAGES.includes(lang) ? lang : 'en',
  websiteCountry: 'CH',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loading: (state) => ({ ...state, isDataLoading: true }),
    fetchUserDataSuccess: (state, action) => {
      return {
        ...state,
        ...action.payload,
        isDataLoading: false,
      };
    },
    fetchUserDataFailed: (state, action) => {
      return {
        ...state,
        error: action.payload.error,
        isDataLoading: false,
      };
    },
    updateWebsiteLanguage: (state, action) => {
      return {
        ...state,
        websiteLanguage: action.payload,
      };
    },
    fetchUserSearchHistorySuccess: (state, action) => {
      return {
        ...state,
        searchHistory: action.payload,
        isDataLoading: false,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loading,
  fetchUserDataSuccess,
  fetchUserDataFailed,
  fetchUserPublicDataSuccess,
  fetchUserSearchHistorySuccess,
  updateWebsiteLanguage,
} = userSlice.actions;

export default userSlice.reducer;
