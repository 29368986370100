import { useState } from 'react';
import { updateLanguage } from '../../../app/helper/general';
import { IconAssets } from '../../../assets/assetProvider';
import { Button } from '../../atoms';
import { useSelector } from 'react-redux';
import { translate } from '../../../app/i18n';
import { useGetCountryLanguages } from '../../../hooks/useCountryLanguagesQuery';

export default function LanguagesDropdown({ setOpen }) {
  const { websiteLanguage, websiteCountry } = useSelector(
    (state) => state.user,
  );
  const [selectedLang, setSelectedLang] = useState(websiteLanguage);
  const pathname = window.location.pathname;
  const searchQuery = new URLSearchParams(window.location.search);
  const languages = useGetCountryLanguages(websiteCountry)?.data;

  const handleSubmit = () => {
    updateLanguage(selectedLang, pathname, searchQuery);
    setOpen && setOpen(false);
  };

  return (
    <div className="languages-dropdown">
      <span className="languages-dropdown_title">
        {translate('languagesDropdown.selectLanguage')}
      </span>
      <hr className="languages-dropdown_divider" />
      {languages?.map((language, index) => {
        return (
          <div
            className="languages-dropdown_item"
            key={index}
            onClick={() => setSelectedLang(language.shortName)}
          >
            <div className="languages-dropdown_item_wrapper">
              <img
                className="languages-dropdown_item_wrapper_img"
                src={IconAssets.languageFlags[language.shortName]}
              />
              <span className="languages-dropdown_item_wrapper_text">
                {language.longName} - {language.shortName.toUpperCase()}
              </span>
            </div>
            {selectedLang === language.shortName && (
              <img src={IconAssets.verifiedIcon} />
            )}
          </div>
        );
      })}
      <div className="languages-dropdown_button">
        <Button
          type="submit"
          onClick={() => handleSubmit()}
          disabled={
            selectedLang && selectedLang !== websiteLanguage ? false : true
          }
        >
          {translate('languagesDropdown.submit')}
        </Button>
      </div>
    </div>
  );
}
