import { Carousel, Wrapper } from './Swiper.styled';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive/src';
import { breakpoints } from '../../../constants/general';

const passive = {
  passive: true,
};

export default function Swiper({
  children,
  spacing,
  containerStyle,
  handleChange,
  innerContainerStyle,
  innerContainerClassName,
  onResize,
}) {
  const { websiteLanguage } = useSelector((state) => state.user);
  /**
   * @type {React.MutableRefObject<HTMLDivElement>}
   */
  const carousel = useRef();
  const isDragStart = useRef(false);
  const prevPageX = useRef();
  const prevScrollLeft = useRef();
  const positionDiff = useRef();
  const isClickable = useRef(true);
  const isMobile = useMediaQuery({ query: breakpoints.mobile_max_width });
  const isTablet = useMediaQuery({ query: breakpoints.tablet_max_width });
  const swiper = useRef({
    next: () => {
      navigate('next');
    },
    previous: () => {
      navigate('previous');
    },
    slideTo: (page) => {
      navigateTo(page);
    },
    hasNext: false,
    hasPrevious: false,
    pages: 1,
    currentPage: 1,
    pageWidth: 0,
  });

  useEffect(() => {
    /**
     * @type {HTMLDivElement}
     */
    const slider = carousel.current;
    slider.addEventListener('mousedown', dragStart);
    slider.addEventListener('touchstart', dragStart, passive);
    document.addEventListener('mousemove', dragging);
    document.addEventListener('touchmove', dragging, passive);
    document.addEventListener('mouseup', dragStop);
    document.addEventListener('touchend', dragStop, passive);
    window.addEventListener('resize', updatePageWidth, passive);
    carousel.current = slider;
    updateSwiper(slider.scrollLeft);
    updatePageWidth();

    return () => {
      slider.removeEventListener('mousedown', dragStart);
      slider.removeEventListener('touchstart', dragStart);
      document.removeEventListener('mousemove', dragging);
      document.removeEventListener('touchmove', dragging);
      document.removeEventListener('mouseup', dragStop);
      document.removeEventListener('touchend', dragStop);
      window.removeEventListener('resize', updatePageWidth);
    };
  }, []);

  const scrollTo = (left, behavior) => {
    if (!isMobile && !isTablet) {
      carousel.current.scrollTo({ left, behavior });
    }
  };

  const getScrollWidth = () => {
    return carousel.current.scrollWidth - carousel.current.clientWidth;
  };

  const getScrollSize = (distance) => {
    const scrollWidth = getScrollWidth();
    const size = distance < scrollWidth ? distance : scrollWidth;
    if (
      (websiteLanguage !== 'ar' && size < 0) ||
      (websiteLanguage === 'ar' && size > 0)
    ) {
      return 0;
    }
    return size;
  };

  const updatePageWidth = (ev) => {
    swiper.current.pageWidth = carousel.current.clientWidth;
    if (onResize) onResize({ width: swiper.current.pageWidth });
    if (ev) {
      const length = autoScroll(prevScrollLeft.current);
      scrollTo(length, 'smooth');
      updateSwiper(length);
    }
  };

  const updateSwiper = (scrollDistance = 0, page = null) => {
    const scrollWidth = getScrollWidth();
    const left = getScrollSize(scrollDistance);

    swiper.current.pageWidth = carousel.current.clientWidth;
    swiper.current.pages = Math.ceil(
      carousel.current.scrollWidth / swiper.current.pageWidth,
    );

    if (page) {
      swiper.current.currentPage = page;
    } else if (Math.abs(left) < swiper.current.pageWidth) {
      swiper.current.currentPage = 1;
    } else {
      const padding =
        Math.abs(carousel.current.scrollLeft) % swiper.current.pageWidth
          ? 2
          : 1;
      swiper.current.currentPage =
        Math.floor(
          Math.abs(carousel.current.scrollLeft) / swiper.current.pageWidth,
        ) + padding;
    }

    swiper.current.hasPrevious = left !== 0;

    if (websiteLanguage !== 'ar') {
      swiper.current.hasNext =
        carousel.current.scrollWidth > carousel.current.clientWidth &&
        left < scrollWidth;
    } else {
      swiper.current.hasNext =
        carousel.current.scrollWidth > carousel.current.clientWidth &&
        left > -scrollWidth;
    }

    if (handleChange) handleChange({ ...swiper.current });
  };

  const autoScroll = (scrollDistance = 0) => {
    const left = getScrollSize(scrollDistance);
    const scrollWidth = getScrollWidth();
    if (left === 0 || left === scrollWidth) return left;

    const firstChild = carousel.current.children[0];
    const itemWidth = firstChild.clientWidth + spacing;

    if (left > prevScrollLeft.current) {
      // Scrolling Right
      const itemCount = Math.ceil(left / itemWidth);
      return itemCount * itemWidth;
    } else {
      // Scrolling Left
      const itemCount = Math.floor(left / itemWidth);
      return itemCount * itemWidth;
    }
  };

  const dragStart = (e) => {
    if (e.type === 'mousedown') {
      e.preventDefault();
    }
    isDragStart.current = true;
    prevPageX.current = e.pageX || (e.touches[0] && e.touches[0].pageX);
    prevScrollLeft.current = carousel.current.scrollLeft;
  };

  const dragging = (e) => {
    if (!isDragStart.current) return;
    if (e.type === 'mousemove') {
      e.preventDefault();
      isClickable.current = false;
    }
    positionDiff.current =
      (e.pageX || (e.touches && e.touches[0] && e.touches[0].pageX)) -
      prevPageX.current;
    scrollTo(prevScrollLeft.current - positionDiff.current, 'auto');
  };

  const navigateTo = (page) => {
    const left = swiper.current.pageWidth * page - swiper.current.pageWidth;
    const scrollDistance = left;
    const scrollLength = autoScroll(scrollDistance);
    scrollTo(scrollLength, 'smooth');
    updateSwiper(scrollLength, page);
  };

  const navigate = (direction) => {
    let left;

    if (
      (direction === 'next' && websiteLanguage !== 'ar') ||
      (direction === 'previous' && websiteLanguage === 'ar')
    ) {
      left = carousel.current.scrollLeft + swiper.current.pageWidth;
    } else {
      left = carousel.current.scrollLeft - swiper.current.pageWidth;
    }
    const scrollLength = autoScroll(left);
    scrollTo(scrollLength, 'smooth');
    updateSwiper(scrollLength);
  };

  const dragStop = (e) => {
    if (!isDragStart.current) return;
    if (e.type === 'mouseup') {
      e.preventDefault();
    }
    isDragStart.current = false;
    const left = autoScroll(carousel.current.scrollLeft);
    scrollTo(left, 'smooth');
    updateSwiper(left);
  };

  return (
    <Wrapper style={containerStyle}>
      <Carousel
        ref={carousel}
        style={innerContainerStyle}
        className={innerContainerClassName}
        onClickCapture={(e) => {
          if (!isClickable.current) {
            e.preventDefault();
            e.stopPropagation();
            isClickable.current = true;
          }
        }}
        gap={spacing}
        lang={websiteLanguage}
      >
        {children}
      </Carousel>
    </Wrapper>
  );
}

Swiper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  spacing: PropTypes.number,
  containerStyle: PropTypes.object,
  innerContainerStyle: PropTypes.object,
  innerContainerClassName: PropTypes.string,
  handleChange: PropTypes.func,
  onResize: PropTypes.func,
};
