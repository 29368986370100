import axios from 'axios';
import services from '..';
import config from '../../config';
import { HTTPErrorHandler } from './HTTPErrorHandler';
import { PUBLIC_API } from '../../constants';
import Fingerprint from '../Fingerprint';
import { getRemoteConfigAsync } from '../../app/helper/firebaseConfigs';

export default () => {
  // eslint-disable-next-line
  let store;

  const instance = axios.create({
    baseURL: config.BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      'client-language': window.localStorage.i18nextLng,
    },
  });

  /**
   * req interceptor
   */
  instance.interceptors.request.use(
    async (req) => {
      // add token for api that needs auth only
      if (!PUBLIC_API.includes(req.url)) {
        try {
          const { token } = await services.authService.checkAuth();
          req.headers.Authorization = `Bearer ${token}`;
        } catch ({ message }) {
          //TODO : edit --> clear only data related to user
          // store.dispatch(destroySession());
        }
      }

      const data = await getRemoteConfigAsync('ABTester');
      // add fingerprint

      req.headers['device-id'] = await Fingerprint.getFingerprint();
      req.headers['ab-tester'] = data.asBoolean();
      return req;
    },
    (err) => {
      return Promise.reject(HTTPErrorHandler(err));
    },
  );

  /**
   * res interceptor
   */
  instance.interceptors.response.use(
    (res) => {
      return Promise.resolve(res.data);
    },
    async (err) => {
      return Promise.reject(HTTPErrorHandler(err.response));
    },
  );

  /**
   * @see https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files
   */
  const injectStore = (_store) => {
    // eslint-disable-next-line unused-imports/no-unused-vars
    store = _store;
  };

  return Object.freeze({
    client: instance,
    injectStore,
  });
};
