import PropTypes from 'prop-types';
export default function Price({
  price,
  label,
  labelClassName,
  priceClassName,
  currencyClassName,
  wrapperClassName,
  position,
  buttonTextBesideLabel,
  buttonFunction,
  disabled,
}) {
  return (
    <div
      className={`current-price current-price--${position} ${wrapperClassName} ${
        (!price || disabled) && 'disabled'
      }`}
    >
      {label && (
        <small className={`current-price__small-label ${labelClassName}`}>
          {label}
        </small>
      )}
      {buttonTextBesideLabel && (
        <small className="current-price__button" onClick={buttonFunction}>
          {buttonTextBesideLabel}
        </small>
      )}
      {price ? (
        <p className={`current-price__value ${priceClassName} `}>
          <sup className={currencyClassName}>{'EUR'}</sup>
          <span>{price}</span>
        </p>
      ) : (
        <p className={` disabled ${priceClassName} `}>-.-</p>
      )}
    </div>
  );
}
Price.propTypes = {
  position: PropTypes.string,
};

Price.defaultProps = {
  position: 'vertical',
};
