import Proptypes from 'prop-types';
import { Button } from './PagerButton.styled';
import { translate } from '../../../app/i18n';

function PagerButton({ action, type, disable }) {
  return (
    <Button aria-label="arrow" disabled={disable} onClick={action}>
      {type === `Previous` ? <i className={'icon-arrow_left_outline'} /> : null}
      <span>
        {type === `Previous`
          ? translate('pagerButtonComponent.previous')
          : translate('pagerButtonComponent.next')}
      </span>
      {type === `Next` ? <i className={'icon-arrow_right_outline'} /> : null}
    </Button>
  );
}

PagerButton.propTypes = {
  action: Proptypes.func,
  type: Proptypes.oneOf([`Next`, `Previous`]),
  disable: Proptypes.bool,
};

export default PagerButton;
