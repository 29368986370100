import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, MazadatLogo } from '../../../atoms';
import { translate } from '../../../../app/i18n';
import config from '../../../../config';
import { IconAssets } from '../../../../assets/assetProvider';
import { PopoverWrapper } from '../../../templates';
import { LanguagesDropdown } from '../../../molecules';

const AuthHeader = () => {
  const { websiteLanguage } = useSelector((state) => state.user);
  const [showLangMenu, setShowLangMenu] = useState(false);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  function handleWindowResize() {
    setWindowSize(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize, { passive: true });
    if (windowSize <= config.SMALL_SCREEN) {
      // setMobileView(true);
    } else {
      // setMobileView(false);
    }

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [windowSize]);

  const handleLangDropdown = () => {
    setShowLangMenu(!showLangMenu);
  };

  const BusinessButton = () => {
    return (
      <Button color="primary-bordered" onClick={() => window.open(config.B2C)}>
        {translate('header.becomeBusiness')}
      </Button>
    );
  };

  return (
    <>
      {
        <div className="authHeaderContainer">
          <div className="authHeaderContainer__black-logo">
            <MazadatLogo />
          </div>
          <div className="authHeaderContainer__authButtons">
            <BusinessButton />
            <div className="authHeaderContainer__authButtons__language-dropdown">
              <button
                aria-label="language"
                className={`button-transparent`}
                onClick={handleLangDropdown}
              >
                <img src={IconAssets.languageFlags[websiteLanguage]} />
                <span>{websiteLanguage.toUpperCase()}</span>
              </button>
              {showLangMenu && (
                <PopoverWrapper show={showLangMenu} setShow={setShowLangMenu}>
                  <LanguagesDropdown />
                </PopoverWrapper>
              )}
            </div>
          </div>
        </div>
      }
      {showLangMenu && <div className="cardProducts-overlay"></div>}
    </>
  );
};
export default AuthHeader;
