export const API = Object.freeze({
  REFRESH_TOKEN: '/api/login/token',
  LOGIN: '/api/login',
  LOGOUT: '/api/login/logout',
  ANONYMOUS_USER: '/api/users/anonymous',
  FORGET_PASSWORD: 'api/users/forget/password/phone',
  RESET_OTP: '/api/users/confirm/otp',
  RESEND_OTP: '/api/users/resendOTP',
  RESET_PASSWORD: '/api/users/new/password/phone',
  GET_POSTS: '/api/post/myPosts/v2',
  GET_ORDERS: '/api/order/ordersAsSeller/v2',
  GET_RETURNS: '/api/returnRequest/asSeller/v2',
  GET_POST_FILTERS: '/api/post/filters',
  GET_RETURN_FILTERS: '/api/returnRequest/filters?userType=seller',
  GET_ORDER_FILTERS: '/api/order/filters?userType=seller',
  GET_INQUIRIES: '/api/question/user/to',
  GET_INQUIRY_MESSAGES: '/api/question',
  POST_INQUIRIES_ANSWER: '/api/question/answer',
  POST_INQUIRIES_IMAGE: '/api/question/answerWithImage',
  GET_TRANSACTIONS: '/api/userTransactions/filters/set',
  GET_TRANSACTION_FILTERS: '/api/userTransactions/filters/get',
  GET_USER_WALLET: '/api/userTransactions/wallet',
  GET_USER_QUESTIONS: '/api/question/user/to',
  PUT_POST_OFFLINE: '/api/post/takeOffline/',
  PUT_POST_ARCHIVE: '/api/post/archive/',
  PUT_POST_REPOST: '/api/post/repost/',
  PUT_POST_RESTOCK: '/api/post/restock/',
  PUT_POST_PROMOTION: '/api/post/promotionPlan/',
  PUT_POST_DISCOUNT: '/api/discount/',
  GET_ALL_PROMOTION_OPTIONS: '/api/home/getAllPromotionOptions',
  PUT_SHARE_POST: '/api/post/dynamicLink',
  CLONE_POST: '/api/post/clone/',
  GET_ORDER_DETAILS: '/api/order/details/',
  GET_USER_DATA: '/api/users/settings/personal',
  UPDATE_USER_LANGUAGE: '/api/users/language/',
  SEARCH_HISTORY: 'api/search/searchHistory',
  DELETE_SEARCH_HISTORY: '/api/search/deleteSearch',
  GET_RETURN_STATUS_HISTORY: '/api/returnRequest/details/',
  GET_RETURN_DETAILS: '/api/returnRequest/',
  GET_POST_DETAILS: '/api/post/v3/',
  GET_SEARCH_RESULTS: '/api/search/esSearch',
  GET_ACTIVE_POSTS: '/api/users/activePosts/',
  GET_POST_QUESTIONS: '/api/question/post/',
  GET_TOP_FIVE_CATEGORIES: '/api/category/top5Cat?imagesExtension=svg',
  GET_HOME_POSTS: '/api/home/dynamicEndpoint', // TODO : make it dynamic
  GET_ENDING_POST_CARDS:
    'api/home/postsEndingSoon?randomize=true&page=1&limit=15', // @TODO : will be dynamic and could be used
  GET_FEATURED_POST_CARDS:
    '/api/home/featuredPosts?randomize=true&page=1&limit=15',
  GET_DAY_DEAL_POST_CARDS: 'api/home/dayDeals?randomize=true&page=1&limit=15',
  GET_MAZADAT_SPOT_LIGHT_POST_CARDS:
    'api/home/mazadatSpot?randomize=true&page=1&limit=15',
  GET_TOP_VIEWED_POST_CARDS:
    '/api/activities/mostViewedPosts?randomize=true&page=1&limit=15',
  GET_PREMIUM_AUCTIONS_CARDS:
    '/api/home/premiumPosts?randomize=true&page=1&limit=15',
  GET_ATTRACTIVE_DISCOUNTS_CARDS:
    '/api/activities/discountedPosts?randomize=true&page=1&limit=15',
  GET_STARTING_FROM_CARDS:
    '/api/home/attractivePosts?randomize=true&page=1&limit=15',
  GET_BUYER_HISTORY: '/api/post/buyer/history',
  GET_NUMBER_OF_SELLER_POSTS: 'api/users/postsSold/',
  GET_BIDDING_HISTORY: '/api/auction/biddingHistory',
  POST_CONTACT_FORM: '/api/contactUs',
  POST_VALIDATE_REGISTRATION_AND_SEND_OTP: '/api/users/validateRegisterSendOTP',
  POST_RESEND_OTP: '/api/users/resendOTP',
  GET_UNIQUE_USER_NAME: '/api/users/username/',
  POST_REGISTRATION_DATA: '/api/users/registerCombined',
  POST_OTP_SUBMIT: '/api/users/submitOTP',
  GET_ALL_CATEGORIES: '/api/category/parents?used=true&imagesExtension=svg',
  GET_CATEGORY: '/api/category',
  GET_PARENT_CATEGORIES: '/api/category/parents',
  GET_CATEGORY_PATH: `/api/category/pathFromRoot`,
  GET_SUB_CATEGORIES: `/api/category/children`,
  GET_CATEGORY_FIELDS: `/api/category/fields`,
  POST_VERIFY_EMAIL: '/api/users/verifyEmailAndSendOTP',
  POST_SET_USER_DATA: '/api/users/settings/personal',
  POST_SUBMIT_EMAIL_OTP: '/api/users/submitEmailOTP',
  GET_SERVICE_POINTS: '/api/servicePoint',
  GET_FAVOURITE_SERVICE_POINT: '/api/servicePoint/favoriteSP',
  GET_AREA_DISTANCE: '/api/location/distance',
  GET_SIMILAR_POST: '/api/search/similarPosts',
  POST_BY_CATEGORY: `/api/search/v2/filterWithCategory`,
  POST_BY_KEYWORD: `/api/search/filterWithSearch`,
  POST_FILTER_FIELD_BY_CATEGORY: `/api/search/v2/updateFiltersCategory`,
  POST_FILTER_FIELD_BY_KEYWORD: `/api/search/updateFiltersKeyword`,
  GET_AUTO_COMPLETE: '/api/search/autocomplete',
  GET_USER_PUBLIC_DETAILS: '/api/users/publicDetails',
  VERIFY_RECAPTCHA_TOKEN: 'api/users/verifyRecaptcha',
  CATEGORY_ADD_TO_FAVOURITES: '/api/category/like',
  CATEGORY_REMOVE_FROM_FAVOURITES: '/api/category/dislike',
  GET_FAVOURITES_CATEGORIES: '/api/category/favorites?imagesExtension=svg',
  ADD_TO_WATCHLIST: '/api/watchList/add',
  REMOVE_FROM_WATCHLIST: '/api/watchList/remove',
  GET_WATCHLIST: 'api/watchList', // No esId
  GET_CATEGORY_FEATURED_POST: '/api/search/v2/featuredWithCategory',
  GET_SEARCH_FEATURED_POST: '/api/search/featuredWithKeyword',
  GET_SELLER_PROFILE_REVIEWS: '/api/review/combinedReviews',
  PUT_UPDATE_INSTANCE: '/api/users/updateInstance',
  GET_SHOPPING_CART_COUNT: '/api/shoppingCart/postsCount',
  GET_NOTIFICATION_COUNT: '/api/notifications/history',
  GET_ACTIONS: '/api/action/getActions',
  UPDATE_USER_COUNTRY: '/api/users/country',
  GET_COUNTRY_LANGUAGES: '/api/i18n/countryLanguages',
});

export const PUBLIC_API = [API.REFRESH_TOKEN, API.LOGIN];
