import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Nav } from 'react-bootstrap';
import { translate } from '../../../../../app/i18n';
import { routeConstants } from '../../../../../constants';
import { PostCard } from '../../../../organisms';
import PostHoc from '../../../../higherOrderComponents/postHoc/postHoc';
import services from '../../../../../services';
import { CardSliderWrapper } from '../../../../templates';
import config from '../../../../../config';
import {
  existDynamicSections,
  handleResponse,
  sectionsTitle,
} from '../../../../../app/helper/general';
import { snackboxOpen } from '../../../../../redux/actions/snackboxAction';
import { useHomePostsQuery } from '../../../../../hooks/usePostQuery';
import { useMediaQuery } from 'react-responsive';
import SearchForm from '../searchHeader/searchForm';
import events from '../../../../../utils/events';
import { useNavigate } from 'react-router-dom';
import { searchAction } from '../../../../../redux/actions/overlayAction';

const CategoriesHeader = ({ hideSlider }) => {
  const { websiteLanguage, websiteCountry } = useSelector(
    (state) => state.user,
  );
  const { showMegaMenu, showSearch } = useSelector((state) => state.overlay);
  const [showPostsSlider, setShowPostsSlider] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedTab, setSelectedTab] = useState('');
  const [showLangMenu, setShowLangMenu] = useState(false);
  const [cardsData, setCardsData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { data: homePosts } = useHomePostsQuery();
  const dispatch = useDispatch();
  const filteredData = existDynamicSections(homePosts, 'header');
  const isTablet = useMediaQuery({ query: '(max-width: 912px)' });
  const navigateTo = useNavigate();
  const [searchDropDownContent, setSearchDropDownContent] = useState({
    showSearchResults: false,
    showLoading: false,
    showSearchDropDown: true,
  });

  const escapeHandler = (event) => {
    if (event.key === 'Escape') {
      onHandleCloseOverlay();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escapeHandler);
    window.addEventListener('click', onHandleCloseOverlay);
    return () => {
      document.removeEventListener('keydown', escapeHandler);
      window.removeEventListener('click', onHandleCloseOverlay);
    };
  }, []);

  const showPosts = (endpoint) => {
    if (showOverlay && showPostsSlider && selectedTab === endpoint) {
      onHandleCloseOverlay();
    } else {
      setShowOverlay(true);
      setShowPostsSlider(true);
      setSelectedTab(endpoint + '&page=1&limit=12');
    }
  };

  const onHandleCloseOverlay = () => {
    setSearchDropDownContent({
      ...searchDropDownContent,
      showSearchDropDown: true,
    });
    setShowPostsSlider(false);
    setShowOverlay(false);
    setShowLangMenu(false);
    setSelectedTab('');
  };

  const setShowOverLay = (show) => {
    dispatch(searchAction(show));
  };

  const handleSearch = (searchInput) => {
    onHandleCloseOverlay();
    navigateTo({
      pathname: `/search`,
      search: `keyword=${encodeURIComponent(searchInput)}`,
    });
    dispatch(searchAction(false));
    events.logSearch(searchInput);
  };

  const Card = PostHoc(PostCard);

  useEffect(() => {
    if (hideSlider === false) {
      setShowPostsSlider(hideSlider);
      setShowOverlay(hideSlider);
      document.querySelectorAll('.selected-nav-slider').forEach((element) => {
        element.classList.remove('nav-active');
      });
    }
  }, [hideSlider]);

  const fetchPostCard = async () => {
    setIsLoading(true);
    try {
      const result = await services.headerPostCards.getPostCards(
        config.API_URL + selectedTab,
      );
      setCardsData(handleResponse(result));
      setIsLoading(false);
    } catch ({ message }) {
      dispatch(snackboxOpen(message, 'failure'));
    }
  };

  useEffect(() => {
    fetchPostCard();
  }, [selectedTab]);

  const MegaMenuSection = ({ url, title, obj }) => {
    return (
      <>
        <Nav.Item className={selectedTab === url ? 'nav-active' : ''}>
          <Nav.Link
            onClick={() => showPosts(url)}
            className={`selected-nav-slider`}
            href=""
          >
            {sectionsTitle(obj.key, title, websiteCountry, websiteLanguage)}
          </Nav.Link>
        </Nav.Item>
      </>
    );
  };

  return (
    <>
      {!isTablet || (showMegaMenu && !showSearch) ? (
        <div
          className="categories-header"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {/* scroll menu only on mobile view */}
          <div className="categories-header__scrollable scrollable scrollable-mobile">
            <Nav activeKey={routeConstants.HOME}>
              <div className="nav-links">
                {filteredData?.map((el, index) => {
                  return (
                    <MegaMenuSection
                      key={index}
                      obj={el}
                      url={el.url}
                      title={el.title}
                    />
                  );
                })}
              </div>
            </Nav>
          </div>
          {showPostsSlider && (
            <div className={'categories-header__body'}>
              <a
                onClick={onHandleCloseOverlay}
                aria-label="close"
                className="button-hyperlink button-hyperlink--borderd button-hyperlink--secondary nav-close"
              >
                {translate('megaMenu.close')}
              </a>
              <CardSliderWrapper
                data={cardsData}
                className="slider-container"
                spinnerClassName="no-cards-wrapper"
                isDataLoading={isLoading}
                zerostateText={translate('postCardsSlider.zeroState')}
                showCardOnlyIfAvailable={true}
                spaceBetween={30}
                itemWidth={270}
                swiperContainerClassName={'swiper-inner-wrapper'}
                swiperSizing={'static'}
              >
                <Card
                  closeOverlay={onHandleCloseOverlay}
                  themeColor="light"
                  isSmallCard={false}
                  cardClassName="categories-header__card"
                />
              </CardSliderWrapper>
            </div>
          )}
          {showPostsSlider && showOverlay && (
            <div onClick={onHandleCloseOverlay} className="overlay"></div>
          )}
        </div>
      ) : (
        isTablet &&
        showSearch && (
          <SearchForm
            {...{
              handleSearch,
              onHandleCloseOverlay,
              setShowOverLay,
              setShowCreatePostOptions: () => {},
              searchDropDownContent,
              setSearchDropDownContent,
            }}
            className="mobile-search-container"
          />
        )
      )}
      {showLangMenu && showOverlay && (
        <div
          onClick={onHandleCloseOverlay}
          className="cardProducts-overlay"
        ></div>
      )}
    </>
  );
};

export default CategoriesHeader;
