import styled from 'styled-components';

export const LegalContainer = styled.div`
  display: flex;
  padding: ${(props) =>
    props.lang !== 'ar' ? '30px 5% 50px 17%' : '30px 17% 50px 5%'};

  @media screen and (max-width: 1440px) {
    padding: ${(props) =>
      props.lang !== 'ar' ? '30px 3% 50px 15%' : '30px 15% 50px 3%'};
  }
  @media screen and (max-width: 1280px) {
    padding: ${(props) =>
      props.lang !== 'ar' ? '30px 3% 50px 10%' : '30px 10% 50px 3%'};
  }
  @media screen and (max-width: 850px) {
    padding: 30px 2% 50px;
  }

  @media screen and (max-width: 650px) {
    display: block;
    padding: 30px 20px 50px;
  }
`;

export const LegalContainerLinks = styled.div`
  margin-right: ${(props) => (props.lang !== 'ar' ? '30px' : 'unset')};
  margin-left: ${(props) => (props.lang !== 'ar' ? 'unset' : '30px')};
`;

export const ButtonWrapper = styled.div`
  margin-left: ${(props) => (props.lang !== 'ar' ? '15%' : 'unset')};
  margin-right: ${(props) => (props.lang !== 'ar' ? 'unset' : '15%')};

  button {
    background-color: ${(props) => props.theme.colors.ON_SURFACE_MEDIUM};
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 6px 14px 15px;
    position: sticky;
    position: -webkit-sticky;
    top: 85vh;
    color: ${(props) => props.theme.colors.BACKGROUND};
  }

  div {
    display: flex;
    flex-direction: column;
  }

  span {
    font-size: ${(props) => props.theme.typography.FS_24};
  }

  @media screen and (max-width: 1024px) {
    margin-left: ${(props) => (props.lang !== 'ar' ? '5%' : 'unset')};
    margin-right: ${(props) => (props.lang !== 'ar' ? 'unset' : '5%')};
  }

  @media screen and (max-width: 913px) {
    margin-left: ${(props) => (props.lang !== 'ar' ? '2%' : 'unset')};
    margin-right: ${(props) => (props.lang !== 'ar' ? 'unset' : '2%')};
  }

  @media screen and (max-width: 650px) {
    p {
      display: none;
    }

    button {
      width: 42px;
      height: 42px;
      padding: 10px 6px 17px;
      position: fixed;
      right: ${(props) => (props.lang !== 'ar' ? '10px' : 'unset')};
      left: ${(props) => (props.lang !== 'ar' ? 'unset' : '10px')};
      bottom: 70px;
      top: unset;
    }
  }
`;
