import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import store from './redux/store';
import { LocaleProvider } from './app/i18n';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './index.scss';
import { injectStore } from './services/HttpClient';
import { socket, SocketContext } from './socket/socket';
import { colors, typography, zIndex } from './app/theme';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StrictMode } from 'react';
import fetchActivateFirebase from './app/helper/firebaseConfigs';
import App from './app/App';
import registerServiceWorker from 'react-service-worker';

/**
 * Inject store for axios interceptors.
 *
 * @see https://github.com/axios/axios#interceptors
 */
injectStore(store);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // During development, this will probably be triggered more frequently
      refetchOnWindowFocus: false, // Stop data re-fetch on browser tab switch or focus
      cacheTime: 1000 * 60 * 10, // Keep data for 10 minutes before garbage collection
    },
  },
});

fetchActivateFirebase();

const appSW = registerServiceWorker();

ReactDOM.render(
  <StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <LocaleProvider>
          <SocketContext.Provider value={socket}>
            <QueryClientProvider client={queryClient}>
              <ThemeProvider theme={{ colors, typography, zIndex }}>
                <App appServiceWorker={appSW} />
              </ThemeProvider>
            </QueryClientProvider>
          </SocketContext.Provider>
        </LocaleProvider>
      </Provider>
    </CookiesProvider>
  </StrictMode>,
  document.getElementById('root'),
);
