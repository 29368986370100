import { useSelector } from 'react-redux';
import propTypes from 'prop-types';
import { translate } from '../../../app/i18n';
import { IconAssets } from '../../../assets/assetProvider';

export default function MazadatValet({ type, onClick }) {
  const { websiteLanguage } = useSelector((state) => state.user);
  return (
    <div onClick={onClick} className={`mazadat-valet ${type}`}>
      <div className="mazadat-valet_wrapper">
        <img
          className="mazadat-valet_wrapper_icon"
          src={IconAssets.valetIcon}
          alt="valet-icon"
        />
        <span className="mazadat-valet_wrapper_title">
          {translate('createPostButton.mazadatValetText')}
        </span>
      </div>
      <div className="mazadat-valet_wrapper">
        <span className="mazadat-valet_wrapper_label">
          {translate('createPostButton.mazadatValetLabel')}
        </span>
        <span
          className={`${
            websiteLanguage !== 'ar' ? 'icon-arrow-right' : 'icon-arrow-left'
          }`}
        ></span>
      </div>
    </div>
  );
}

MazadatValet.propTypes = {
  type: propTypes.oneOf(['web', 'mobile']),
};

MazadatValet.defaultProps = {
  type: 'web',
};
